import React from 'react';
// Redux
import { useSelector, useDispatch } from 'react-redux';
// Components
import Container from '../../Components/Container';
import Pagination from '../../Components/Pagination';
import SummarySearch from '../../Components/SummarySearch';
import { LoadingSkeleton } from '../../Components/LoadingSkeleton';
import { SkeletonTypes } from '../../Components/LoadingSkeleton/types';
// Slice
import {
	setMultimedia,
	setIsMultimediaLoading,
	setPageMultimediaShowed,
	setPageMultimediaFetched,
} from '../../app/features/multimediaSlice';
// Services
import { getMultimedia } from '../../services/getMultimedia';
// Hooks
import { useScrollToTop } from '../../hooks/useScrollToTop';
// Styles
import './ResourcesPage.css';
import { FavoriteCategory } from '../FavoriteDetail/types';
import { useAuth } from '../../hooks/useAuth';
import MediaCard from '../../Components/MediaCard';

const MediaResourcesPage = () => {
	const dispatch = useDispatch();
	const { isAuth } = useAuth();
	const { handleScrollToTop } = useScrollToTop();

	const {
		multimedia,
		is_multimedia_loading,
		total_multimedia,
		pageMultimediaShowed,
		pageMultimediaFetched,
	} = useSelector((state) => state.multimedia);

	const { queryFetched } = useSelector((state) => state.library);
	// const itemsPerPage = 12;

	// Pagination
	const itemsPerPage = 12;
	const indexOfLastItem = pageMultimediaShowed * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = multimedia.slice(indexOfFirstItem, indexOfLastItem);
	const totalPages = Math.ceil(total_multimedia / itemsPerPage);
	const totalCurrencyPages = Math.ceil(multimedia?.length / itemsPerPage);

	const handlePageChange = (newPage) => {
		handleScrollToTop(0);
		if (newPage > pageMultimediaShowed) {
			dispatch(setPageMultimediaShowed(newPage));
			if (newPage > totalCurrencyPages - 1) {
				dispatch(setPageMultimediaFetched(pageMultimediaFetched + 1));
				getMultimedia(queryFetched, pageMultimediaFetched + 1)
					.then((response) => {
						dispatch(setMultimedia(response?.data?.data));
						dispatch(setIsMultimediaLoading(false));
					})
					.catch((error) => {
						console.error('Request error:', error);
					});
			}
		} else {
			if (pageMultimediaShowed > 1) {
				dispatch(setPageMultimediaShowed(newPage));
			}
		}
	};

	return (
		<>
			{is_multimedia_loading && (
				<main className='media-content'>
					<Container>
						<section className='mediaResults'>
							{Array.from({ length: 12 }).map((_, i) => (
								<LoadingSkeleton
									key={i}
									type={SkeletonTypes.MEDIA_RESOURCE_CARD}
								/>
							))}
						</section>
					</Container>
				</main>
			)}

			{multimedia?.length > 0 ? (
				<main className='media-content'>
					<Container>
						<SummarySearch total={total_multimedia} />

						<section>
							<div className='mediaResults'>
								{currentItems?.map((libraryItem, i) => (
									<MediaCard
										key={i}
										image={libraryItem.thumbnail}
										time={libraryItem.duration}
										title={libraryItem.title}
										description={libraryItem.description}
										url={libraryItem.video_url}
										language={'EN'}
										type={'new_tab'}
										favoriteCategory={FavoriteCategory.MEDIA}
										showFavoriteButton={isAuth() ? true : false}
									/>
								))}
							</div>
							<Pagination
								pageShowed={pageMultimediaShowed}
								totalPages={totalPages}
								handlePageChange={handlePageChange}
							/>
						</section>
					</Container>
				</main>
			) : is_multimedia_loading === false &&
			  total_multimedia === 0 &&
			  queryFetched.length === 0 ? (
				<main className='noDataContainer'>
					<p>Please enter a search term</p>
				</main>
			) : (
				<main className='noDataContainer'>
					<p>No data found</p>
				</main>
			)}
		</>
	);
};

export default MediaResourcesPage;
