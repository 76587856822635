import React from 'react';
// Redux
import { useSelector, useDispatch } from 'react-redux';
// Components
import Card from '../../Components/Card';
import Container from '../../Components/Container';
import Pagination from '../../Components/Pagination';
import SummarySearch from '../../Components/SummarySearch';
import FiltersPatents from '../../Components/FiltersPatents';
import { LoadingSkeleton } from '../../Components/LoadingSkeleton';
import { SkeletonTypes } from '../../Components/LoadingSkeleton/types';
// Slices
import { setPagePatents } from '../../app/features/patentsSlice';
// Hooks
import { usePatents } from '../../hooks/usePatents';
import { useScrollToTop } from '../../hooks/useScrollToTop';
// Styles
import './Patents.css';
import { FavoriteCategory } from '../FavoriteDetail/types';
import { useAuth } from '../../hooks/useAuth';

const PatentsPage = () => {
	const dispatch = useDispatch();
	const { isAuth } = useAuth();
	// Hooks
	const { getPatentsDataSocket } = usePatents();
	const { handleScrollToTop } = useScrollToTop();

	// Slices
	const { patents, filters, pagePatents, isLoadingPatents, totalPatents } =
		useSelector((state) => state.patents);

	// Pagination
	const itemsPerPage = 10;
	const indexOfLastItem = pagePatents * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = patents.slice(indexOfFirstItem, indexOfLastItem);
	const totalPages = Math.ceil(totalPatents / itemsPerPage);

	const handlePageChange = (newPage) => {
		handleScrollToTop(0);
		if (newPage > pagePatents) {
			if (
				pagePatents < totalPages ||
				(Math.ceil(patents?.length / itemsPerPage) > 1 && totalPages === 0)
			) {
				dispatch(setPagePatents(newPage));
				if (newPage >= Math.ceil(patents?.length / itemsPerPage) - 1) {
					getPatentsDataSocket(
						filters.queryFetched,
						filters.pageFetched + 1,
						filters.startYearFetched,
						filters.endYearFetched,
						filters.sourcesPatentsFetched
					);
				}
			}
		} else {
			if (pagePatents > 1) {
				dispatch(setPagePatents(newPage));
			}
		}
	};

	return (
		<>
			{isLoadingPatents && (
				<main className='patents-content'>
					<Container>
						<section className='results'>
							{Array.from({ length: 10 }).map((_, i) => (
								<LoadingSkeleton key={i} type={SkeletonTypes.LIBRARY_CARD} />
							))}
						</section>
					</Container>
				</main>
			)}

			{patents?.length > 0 ? (
				<main className='patents-content'>
					<Container>
						<div className='metasearchLibraryContainer__info'>
							<SummarySearch total={totalPatents} />
							<FiltersPatents />
						</div>

						<section>
							<div className='results'>
								{currentItems?.map((patent, i) => {
									return (
										<Card
											key={i}
											title={patent.title}
											authors={[patent.authors]}
											abstract={patent.abstract}
											publication_date={patent.date}
											url={patent.url}
											type={patent.type}
											resource_origin={
												patent.source_code
													? patent.source_code.replace('_', ' ')
													: ''
											}
											peer_reviewed={patent.peer_reviewed}
											favoriteCategory={FavoriteCategory.PATENTS}
											showFavoriteButton={isAuth() ? true : false}
										/>
									);
								})}
							</div>
							<Pagination
								pageShowed={pagePatents}
								totalPages={totalPages}
								handlePageChange={handlePageChange}
							/>
						</section>
					</Container>
				</main>
			) : isLoadingPatents === false &&
			  totalPatents === 0 &&
			  filters?.queryPatents?.length === 0 ? (
				<main className='noDataContainer'>
					<p>Please enter a search term</p>
				</main>
			) : (
				<main className='noDataContainer'>
					<p>No data found</p>
				</main>
			)}
		</>
	);
};

export default PatentsPage;
