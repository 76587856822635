import React, { useEffect, useState } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Services
import { sendUserSearchInformation } from '../../services/sendUserSearchInformation';
// Utils
import { publicationDate } from '../../utils/publicationDate';
// Styles
import './CardNews.css';
import { FavoriteCategory } from '../../Views/FavoriteDetail/types';
import {
	getFavoriteByUrl,
	handleDeleteFavorite,
} from '../FavoriteButton/utils';
import FavoriteButton from '../FavoriteButton';

const CardNews = ({
	title,
	url,
	image_url,
	resource_origin,
	publication_date,
	description,
	index = 0,
	favoriteType = 'favorite', // favorite | detail
	favoriteCategory = FavoriteCategory.NEWS,
	onDeleteFavorite,
	showFavoriteButton = false,
}) => {
	const { user_email } = useSelector((state) => state.user);
	const { institutionCode } = useSelector((state) => state.institution);
	const [favoriteStatus, setFavoriteStatus] = useState(false);
	const dispatch = useDispatch();
	const favorites = useSelector((state) => state.favorites.favorites);

	const handleClicCard = () => {
		sendUserSearchInformation(
			title,
			resource_origin,
			url,
			'news',
			user_email,
			institutionCode
		);
		window.open(url, '_blank');
	};

	useEffect(() => {
		const hasFavorite = getFavoriteByUrl(url, favorites);

		setFavoriteStatus(!!hasFavorite);
	}, [favorites, url]);
	return (
		<div className={index === 1 ? 'cardNews big' : 'cardNews'}>
			<div className='containerImageNews'>
				<img
					className='cardNews__image'
					src={image_url}
					loading='lazy'
					alt={title}
				/>
			</div>

			<div className='cardNewsContainer'>
				<div className='cardNewsSearchedContainer'>
					<div
						className='cardNewsSearchedContainer__header'
						style={{ position: 'relative' }}
					>
						<div className='info'>
							<p className='sourceNewsSearched'>{resource_origin}</p>
							{publication_date && (
								<p className='publicationDateNewsSearched'>
									{publicationDate(publication_date)}
								</p>
							)}
						</div>
						{showFavoriteButton && (
							<FavoriteButton
								topSpacing='-0.4rem'
								bottomTooltip='0rem'
								rightSpacing='0rem'
								rightTooltip='0rem'
								type={favoriteType}
								category={favoriteCategory}
								isFavorite={favoriteStatus}
								favoriteData={{
									url,
									title,
									resource_origin,
									publication_date,
									image_url,
									description,
								}}
								onDeleteFavorite={() =>
									handleDeleteFavorite(
										url,
										favorites,
										favoriteType,
										user_email,
										dispatch,
										onDeleteFavorite
									)
								}
								url={url}
							/>
						)}
					</div>
				</div>
				<h4 className='cardNews__title'>{title}</h4>
				{description && <p className='cardNews__text'>{description}</p>}
				<button className='showMore' onClick={handleClicCard}>
					Show More
				</button>
			</div>
		</div>
	);
};

export default CardNews;
