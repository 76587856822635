import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// Components
import Footer from '../../Components/Footer';
import Container from '../../Components/Container';
// Services
import { sendUserInformation } from '../../services/sendUserInformation';
// Images
import { GrFormNext } from 'react-icons/gr';
import imageAquino from '../../Images/EBooks/aquino.svg';
import imageEBooks from '../../Images/EBooks/ebooks.webp';
import logoOnlineLibrary from '../../Images/logo/logo_OlibBlack.svg';
import imageAquinoReading from '../../Images/EBooks/aquinoReading.svg';
// Styles
import './EBooks.css';

const EBooks = () => {
  const [sectionActive, setSectionActive] = useState(false);

  // Slices
  const { user_email } = useSelector((state) => state.user);
  const { institutionCode } = useSelector((state) => state.institution);

  const handleClicEBooks = () => {
    sendUserInformation('EBooks', 'EBooks', user_email, institutionCode);
    const url =
      institutionCode === 'IHU'
        ? 'https://ebooks7-24.com/?ed=446'
        : 'https://ebooks7-24.com/Stage.aspx';

    window.open(url, '_blank');
  };

  return (
    <div className='EBooks'>
      <Container className='EBooksContainer'>
        <Link to='/articles' className='backButtonEBooks'>
          Back
        </Link>
        <Link to='/' className='logoOnlineLibrary'>
          <img src={logoOnlineLibrary} alt='logo Aquinas Network' />
        </Link>

        {sectionActive ? (
          <div className='EBooksInfo'>
            <div className='EBooksInfoText'>
              <img className='aquinoImage' src={imageAquino} alt='Aquino' />

              <h2>
                <b>Welcome to Aquinas Online Library!</b> You now have exclusive
                access to select books from McGraw Hill. Explore titles in
                management, finance, economics, and more, available to our
                community.
              </h2>
              <p>
                Access the best academic resources and enhance your learning.
                Start discovering today!
              </p>
              <div className='optionButtons'>
                <button
                  className='backButtonEBooks'
                  onClick={() => setSectionActive(false)}
                >
                  Skip
                </button>
                <button className='nextButtonEBooks' onClick={handleClicEBooks}>
                  Go to eBooks <GrFormNext />
                </button>
              </div>
            </div>
            <img className='imageEBooks' src={imageEBooks} alt='Ebooks' />
          </div>
        ) : (
          <div className='EBooksInitial'>
            <img src={imageAquinoReading} alt='Aquino reading Network' />
            <h2>
              <b>Welcome to Aquinas Online Library!</b> Explore our eBooks, now
              featuring exclusive McGraw Hill titles. Find top-quality resources
              in management, economics, and more to support your academic
              journey.
            </h2>
            <p>Click "Next" to start exploring!</p>
            <button
              className='nextButtonEBooks'
              onClick={() => setSectionActive(true)}
            >
              Next <GrFormNext />
            </button>
          </div>
        )}
      </Container>
      <Footer allContent={false} />
    </div>
  );
};

export default EBooks;
