import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	user_email: '', // Shows email
	user_name: '', // Show user name
	user_lastname: '', // Show user lastname
	user_code: '', // Show user code
	ip_login: '', // Shows user IP
	lms: '',
	activeLoginModal: false,
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserEmail: (state, action) => {
			state.user_email = action.payload;
		},
		setUserName: (state, action) => {
			state.user_name = action.payload;
		},
		setUserLastname: (state, action) => {
			state.user_lastname = action.payload;
		},
		setUserCode: (state, action) => {
			state.user_code = action.payload;
		},
		setIpLogin: (state, action) => {
			state.ip_login = action.payload;
		},
		setLms: (state, action) => {
			state.lms = action.payload;
		},
		setActiveLoginModal: (state, action) => {
			state.activeLoginModal = action.payload;
		},
		resetUserData: (state) => {
			state.user_email = '';
			state.user_name = '';
			state.user_lastname = '';
			state.user_code = '';
			state.ip_login = '';
			state.lms = '';
			state.activeLoginModal = false;
		},
	},
});

export const {
	setUserEmail,
	setUserName,
	setUserLastname,
	setUserCode,
	setIpLogin,
	resetUserData,
	setLms,
	setActiveLoginModal,
} = userSlice.actions;

export default userSlice.reducer;
