import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// Components
import CardNews from '../CardNews';
import Container from '../Container';
import Pagination from '../Pagination';
import FiltersNews from '../FiltersNews';
import CardNewsSearched from '../CardNewsSearched';
// Hooks
import { useScrollToTop } from '../../hooks/useScrollToTop';
// Styles
import './NewsBaseSection.css';
import { useAuth } from '../../hooks/useAuth';
import { FavoriteCategory } from '../../Views/FavoriteDetail/types';

const NewsBaseSection = () => {
	const { isAuth } = useAuth();
	const { handleScrollToTop } = useScrollToTop();
	// Variables
	const [pageBaseNews, setPageBaseNews] = useState(1);
	const [selectedSourcesNews, setSelectedSourcesNews] = useState([]);
	// Slices
	const { base_news } = useSelector((state) => state.news);

	useEffect(() => {
		setPageBaseNews(1);
	}, [selectedSourcesNews]);

	// Funcion para modificar las noticias segun fuentes
	let selectedNews = () => {
		if (selectedSourcesNews.length > 0) {
			return base_news?.filter((element) =>
				selectedSourcesNews.includes(element.source)
			);
		} else {
			return base_news;
		}
	};

	// Pagination
	const itemsPerPage = 10;
	const totalNews = selectedNews().length;
	const indexOfLastItem = pageBaseNews * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = selectedNews().slice(indexOfFirstItem, indexOfLastItem);
	const totalPages = Math.ceil(totalNews / itemsPerPage);

	// Funcion de cambio de paginacion
	const handlePageChange = (newPage) => {
		handleScrollToTop(0);
		if (newPage > pageBaseNews) {
			if (
				pageBaseNews < totalPages ||
				(Math.ceil(base_news?.length / itemsPerPage) > 1 && totalPages === 0)
			) {
				setPageBaseNews(newPage);
			}
		} else {
			if (pageBaseNews > 1) {
				setPageBaseNews(newPage);
			}
		}
	};

	return (
		<Container>
			<div className='newsFiltersContainer'>
				<FiltersNews
					change={selectedNews}
					selected={selectedSourcesNews}
					setSelected={setSelectedSourcesNews}
				/>
			</div>
			<section className='base-newsHeader'>
				{currentItems?.slice(0, 2).map((news, i) => {
					return (
						<CardNews
							key={i}
							title={news.title}
							description={news.description}
							url={news.url}
							image_url={news.image_url}
							publication_date={news.publication_date}
							resource_origin={news.source}
							index={i}
							favoriteCategory={FavoriteCategory.NEWS}
							showFavoriteButton={isAuth() ? true : false}
						/>
					);
				})}
			</section>
			<section className='base-newsHeaderSmall'>
				{currentItems?.slice(0, 2).map((news, i) => {
					return (
						<CardNewsSearched
							key={i}
							title={news.title}
							description={news.description}
							url={news.url}
							image_url={news.image_url}
							publication_date={news.publication_date}
							resource_origin={news.source}
							favoriteCategory={FavoriteCategory.NEWS}
							showFavoriteButton={isAuth() ? true : false}
						/>
					);
				})}
			</section>
			<section className='base-news'>
				{currentItems?.slice(2, itemsPerPage).map((news, i) => {
					return (
						<CardNewsSearched
							key={i}
							title={news.title}
							description={news.description}
							url={news.url}
							image_url={news.image_url}
							publication_date={news.publication_date}
							resource_origin={news.source}
							favoriteCategory={FavoriteCategory.NEWS}
							showFavoriteButton={isAuth() ? true : false}
						/>
					);
				})}
			</section>
			<Pagination
				pageShowed={pageBaseNews}
				totalPages={totalPages}
				handlePageChange={handlePageChange}
			/>
		</Container>
	);
};

export default NewsBaseSection;
