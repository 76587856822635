import React, { useState } from 'react';
// Icons
import { BsInfoCircle } from 'react-icons/bs';
// Styles
import './Info.css';

const Info = () => {
  const [showMessage, setShowMessage] = useState(false);

  const getExamples = () => {
    return (
      <div className='messageInfo'>
        <p>
          Aquino is your artificial intelligence assistant designed to conduct
          quick and efficient searches across a wide variety of sources and
          specialized articles.
        </p>
      </div>
    );
  };

  return (
    <button
      className='message-containerInfo'
      onMouseEnter={() => setShowMessage(true)}
      onMouseLeave={() => setShowMessage(false)}
    >
      <BsInfoCircle />
      {showMessage && getExamples()}
    </button>
  );
};

export default Info;
