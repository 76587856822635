import { FavoriteCategory } from './types';

const categoriesWithFourColumns = [FavoriteCategory.MEDIA];

export const canUseFourColumnsGrid = (favoriteCategory) =>
	categoriesWithFourColumns.includes(favoriteCategory);

const categoriesWithThreeColumns = [
	FavoriteCategory.ARTICLES,
	FavoriteCategory.PATENTS,
	FavoriteCategory.BOOKS,
	FavoriteCategory.INTERESTING_LINKS,
	FavoriteCategory.SOFT_SKILLS,
	FavoriteCategory.TUTORIALS,
];

export const canUseThreeColumnsGrid = (favoriteCategory) =>
	categoriesWithThreeColumns.includes(favoriteCategory);

const categoriesWithTwoColumns = [FavoriteCategory.NEWS];

export const canUseTwoColumnsGrid = (favoriteCategory) =>
	categoriesWithTwoColumns.includes(favoriteCategory);
