import React from 'react';
import './deleteFavoriteItemNotification.css';
import '../LinkCopiedNotification/LinkCopiedNotification.css';

const DeleteFavoriteItemNotification = () => {
	return (
		<div>
			<p className='text-small-notification blue-aquinas-text '>
				You have removed this item from your favorites list.
			</p>
		</div>
	);
};
export default DeleteFavoriteItemNotification;
