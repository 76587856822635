import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  resourceType: 'library',
  word: '',
  wordFetched: '',
  wordFetchedAdvanced: '',
  socketLibrary: {},
  socketGallery: {},
  loadingMetasearch: false,
  socketPatents: {},
  loadingPatents: false,
  socketBooks: {},
};

const metaSearchSlice = createSlice({
  name: 'metasearch',
  initialState,
  reducers: {
    setResourceType: (state, action) => {
      state.resourceType = action.payload;
    },
    setWord: (state, action) => {
      state.word = action.payload;
    },
    setWordFetched: (state, action) => {
      state.wordFetched = action.payload;
    },
    setWordFetchedAdvanced: (state, action) => {
      state.wordFetchedAdvanced = action.payload;
    },
    // Library section
    setSocketLibrary: (state, action) => {
      state.socketLibrary = action.payload;
    },
    // Art section
    setSocketGallery: (state, action) => {
      state.socketGallery = action.payload;
    },
    setLoadingMetasearch: (state, action) => {
      state.loadingMetasearch = action.payload;
    },
    // Patents section
    setSocketPatents: (state, action) => {
      state.socketPatents = action.payload;
    },
    setLoadingPatents: (state, action) => {
      state.loadingPatents = action.payload;
    },
    // Books section
    setSocketBooks: (state, action) => {
      state.socketBooks = action.payload;
    },
    resetMetasearch: (state, action) => {
      state.resourceType = 'library';
      state.word = '';
      state.wordFetched = '';
      state.wordFetchedAdvanced = '';
      state.socketLibrary = {};
      state.socketGallery = {};
      state.loadingMetasearch = false;
      state.socketPatents = {};
      state.loadingPatents = false;
      state.socketBooks = {};
    },
  },
});

export const {
  setResourceType,
  setWord,
  setWordFetched,
  setWordFetchedAdvanced,
  setSocketLibrary,
  setSocketGallery,
  setLoadingMetasearch,
  setSocketPatents,
  setLoadingPatents,
  setSocketBooks,
  resetMetasearch,
} = metaSearchSlice.actions;

export default metaSearchSlice.reducer;
