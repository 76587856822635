import { createSlice } from '@reduxjs/toolkit';

// Get initial favorites from localStorage
const getFavoritesFromStorage = () => {
	return JSON.parse(localStorage.getItem('favorites')) || [];
};

const favoritesSlice = createSlice({
	name: 'favorites',
	initialState: {
		favorites: getFavoritesFromStorage(),
	},
	reducers: {
		addNewFullFavorites: (state, action) => {
			state.favorites = action.payload;
		},
		addFavorite: (state, action) => {
			state.favorites.push(action.payload);
			localStorage.setItem('favorites', JSON.stringify(state.favorites));
		},
		removeFavorite: (state, action) => {
			state.favorites = state.favorites.filter(
				(fav) => fav.url !== action.payload
			);
			localStorage.setItem('favorites', JSON.stringify(state.favorites));
		},
		loadFavorites: (state) => {
			state.favorites = getFavoritesFromStorage();
		},
	},
});

// Export actions
export const {
	addFavorite,
	removeFavorite,
	loadFavorites,
	addNewFullFavorites,
} = favoritesSlice.actions;

// Export reducer
export default favoritesSlice.reducer;
