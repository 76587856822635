import React from 'react';
import '../LinkCopiedNotification/LinkCopiedNotification.css';
const DeleteFavoriteNotification = () => {
	return (
		<div>
			<p className='text-small-notification blue-aquinas-text '>
				Successfully deleted.
			</p>
		</div>
	);
};
export default DeleteFavoriteNotification;
