import React, { useState } from 'react';
// Components
import CardVideo from '../../Components/CardVideo';
import Container from '../../Components/Container';
import Pagination from '../../Components/Pagination';
// Hooks
import { useScrollToTop } from '../../hooks/useScrollToTop';
// Services
import { getVideosAmericanLife } from '../../services/getVideosAmericanLife';
// Styles
import './AmericanLifePage.css';
import { FavoriteCategory } from '../FavoriteDetail/types';
import { useAuth } from '../../hooks/useAuth';

const AmericanLife = () => {
	const { isAuth } = useAuth();
	// Hook
	const { handleScrollToTop } = useScrollToTop();
	// Estados
	const [pageShowedTutorials, setPageShowedTutorials] = useState(1);

	// Pagination
	const itemsPerPage = 12;
	const totalNews = getVideosAmericanLife().length;
	const indexOfLastItem = pageShowedTutorials * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = getVideosAmericanLife().slice(
		indexOfFirstItem,
		indexOfLastItem
	);
	const totalPages = Math.ceil(totalNews / itemsPerPage);

	// Funcion de cambio de paginacion
	const handlePageChange = (newPage) => {
		handleScrollToTop(0);
		if (newPage > pageShowedTutorials) {
			if (
				pageShowedTutorials < totalPages ||
				(Math.ceil(getVideosAmericanLife()?.length / itemsPerPage) > 1 &&
					totalPages === 0)
			) {
				setPageShowedTutorials(newPage);
			}
		} else {
			if (pageShowedTutorials > 1) {
				setPageShowedTutorials(newPage);
			}
		}
	};

	return (
		<main className='AmericanLife'>
			<Container>
				<section className='AmericanLifeContainer'>
					{currentItems?.map((video, i) => {
						return (
							<CardVideo
								key={i}
								image={video.image}
								time={video.duration}
								title={video.title}
								description={video.description}
								url={video.url}
								language={video.language}
								favoriteCategory={FavoriteCategory.SOFT_SKILLS}
								showFavoriteButton={isAuth() ? true : false}
							/>
						);
					})}
				</section>
				<Pagination
					pageShowed={pageShowedTutorials}
					totalPages={totalPages}
					handlePageChange={handlePageChange}
				/>
			</Container>
		</main>
	);
};

export default AmericanLife;
