// Utils
import { fetchWithoutToken } from '../utils/fetch';

// Function to send user information for account creation
export const sendCreationAccount = async (fullname, email, institutionCode) => {
  try {
    const res = await fetchWithoutToken.post(
      '/users/register',
      {
        full_name: fullname,
        email,
        institution_code: institutionCode,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    return res;
  } catch (error) {
    console.error(error);
    return error;
  }
};
