export const FavoriteCategory = Object.freeze({
	ARTICLES: 'articles',
	PATENTS: 'patents',
	NEWS: 'news',
	MEDIA: 'media',
	BOOKS: 'books',
	INTERESTING_LINKS: 'interesting-links',
	SOFT_SKILLS: 'soft-skills-and-american-life',
	TUTORIALS: 'tutorials',
});
