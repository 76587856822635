import React, { useState } from 'react';
// Components
import Container from '../Container';
import MultiRangeSlider from '../MultiRangeSlider';
// Utils
import { areListsDiff } from '../../utils/areaListDiff';
import { currentYear } from '../../utils/getYearsFilters';
import { removeElement } from '../../utils/removeElement';
import { spanishInstitutions } from '../../utils/spanishInstitutions';
// Reddux
import { useSelector, useDispatch } from 'react-redux';
// Hooks
import { usePatents } from '../../hooks/usePatents';
import { useScrollToTop } from '../../hooks/useScrollToTop';
// Slices
import {
  setStartYear,
  setEndYear,
  setSelectedSourcesPatents,
  resetPatents,
  setPagePatents,
} from '../../app/features/patentsSlice';
// Icons
import { IoMdOptions } from 'react-icons/io';
import { TbChevronUp } from 'react-icons/tb';
import { IoMdClose } from 'react-icons/io';
// Styles
import './FiltersPatents.css';

const FiltersPatents = () => {
  const dispatch = useDispatch();

  const { getPatentsDataSocket } = usePatents();
  const { handleScrollToTop } = useScrollToTop();

  // State of filters
  const [isOpenSource, setIsOpenSource] = useState(false);
  const [isOpenYears, setIsOpenYears] = useState(false);

  const [activeFilter, setActiveFilter] = useState(false);

  const {
    selectedSourcesPatents,
    startYear,
    endYear,
    totalPatents,
    filters,
    allPatentsSources,
  } = useSelector((state) => state.patents);

  const { institutionCode } = useSelector((state) => state.institution);

  // Función que cambia el estado de las variables startYear y endYear
  const yearSelected = (startYear, endYear) => {
    dispatch(setStartYear(startYear));
    dispatch(setEndYear(endYear));
  };

  // Función que selecciona las bases de datos
  const sourcesSelected = (optionValue) => {
    if (selectedSourcesPatents.includes(optionValue)) {
      dispatch(
        setSelectedSourcesPatents(
          selectedSourcesPatents.filter((value) => value !== optionValue)
        )
      );
    } else {
      dispatch(
        setSelectedSourcesPatents([...selectedSourcesPatents, optionValue])
      );
    }
  };

  // Botón de Aplicar filtros
  const handleApplyFilters = (start, end, sources) => {
    handleScrollToTop(0);
    dispatch(setPagePatents(1));
    getPatentsDataSocket(filters.queryFetched, 1, start, end, sources);
  };

  // Funcion para Borrar filtros
  const handleClearFilters = () => {
    handleScrollToTop(0);
    dispatch(resetPatents());
    getPatentsDataSocket(
      filters.queryFetched,
      1,
      1990,
      currentYear,
      allPatentsSources
    );
  };

  const texts = {
    en: {
      sources: 'Sources',
      year: 'Year',
    },
    es: {
      sources: 'Bases de datos',
      year: 'Año',
    },
  };

  const getTextInLanguage = (key) => {
    const language = spanishInstitutions.includes(institutionCode)
      ? 'es'
      : 'en';
    return texts[language]?.[key] || 'Text not found';
  };

  return (
    <>
      <div className='filtersBarIcon'>
        <b>Filters</b>
        <button onClick={() => setActiveFilter(true)}>
          <IoMdOptions className='optionsIcon' />
        </button>
      </div>

      <section
        className={
          activeFilter ? 'sectionFilter activeFilter' : 'sectionFilter'
        }
      >
        <div
          onClick={() => setActiveFilter(false)}
          className={
            activeFilter
              ? 'filterBarBackground activeFilter'
              : 'filterBarBackground'
          }
        ></div>
        <div className='filtersBar'>
          <Container className='filtersBarMain'>
            <button onClick={() => setActiveFilter(false)}>
              <IoMdClose />
            </button>
            <div>
              <IoMdOptions className='optionsIcon' />
              <h2>Filters</h2>
            </div>
          </Container>

          {(filters?.endYearFetched !== currentYear ||
            filters?.startYearFetched !== 1990 ||
            areListsDiff(
              filters?.sourcesPatentsFetched,
              allPatentsSources
            )) && (
            <Container className='filtersBarSection'>
              <div className='filtersBarSection__main'>
                <h3>Filters</h3>
                <button onClick={handleClearFilters}>Remove all filters</button>
              </div>
              <div className='filtersBarSectionFilter__content'>
                {filters?.startYearFetched !== 1990 && (
                  <button
                    onClick={() =>
                      handleApplyFilters(1990, endYear, selectedSourcesPatents)
                    }
                  >
                    {`Start year: ${filters?.startYearFetched}`} <IoMdClose />
                  </button>
                )}

                {filters?.endYearFetched !== currentYear && (
                  <button
                    onClick={() =>
                      handleApplyFilters(
                        startYear,
                        currentYear,
                        selectedSourcesPatents
                      )
                    }
                  >
                    {`End year: ${filters?.endYearFetched}`}
                    <IoMdClose />
                  </button>
                )}

                {filters?.sourcesPatentsFetched?.length > 0 &&
                  filters?.sourcesPatentsFetched?.map((source, i) => (
                    <button
                      key={i}
                      onClick={() =>
                        handleApplyFilters(
                          startYear,
                          endYear,
                          removeElement(selectedSourcesPatents, source).length >
                            0
                            ? removeElement(selectedSourcesPatents, source)
                            : allPatentsSources
                        )
                      }
                    >
                      {source} <IoMdClose />
                    </button>
                  ))}
              </div>
            </Container>
          )}

          <Container className='filtersBarSection'>
            <div
              className='filtersBarSection__main'
              onClick={() => setIsOpenSource(!isOpenSource)}
              role='button'
            >
              <h3>{getTextInLanguage('sources')}</h3>

              <button>
                <TbChevronUp className={isOpenSource ? '' : 'iconActive'} />
              </button>
            </div>
            {isOpenSource && (
              <div className='filtersBarSection__content fixed'>
                {allPatentsSources?.map((source, i) => {
                  return (
                    <button
                      key={i}
                      className={
                        selectedSourcesPatents.includes(source)
                          ? 'selected'
                          : ''
                      }
                      onClick={() => sourcesSelected(source)}
                    >
                      {source.replace('_', ' ')}
                    </button>
                  );
                })}
              </div>
            )}
          </Container>

          <Container className='filtersBarSection'>
            <div
              className='filtersBarSection__main'
              onClick={() => setIsOpenYears(!isOpenYears)}
              role='button'
            >
              <h3>{getTextInLanguage('year')}</h3>
              <button>
                <TbChevronUp className={isOpenYears ? '' : 'iconActive'} />
              </button>
            </div>
            {isOpenYears && (
              <div className='filtersBarSection__content'>
                <MultiRangeSlider
                  min={startYear}
                  max={endYear}
                  onChange={({ min, max }) => yearSelected(min, max)}
                />
              </div>
            )}
          </Container>

          {(startYear !== filters.startYearFetched ||
            endYear !== filters.endYearFetched ||
            areListsDiff(
              selectedSourcesPatents,
              filters.sourcesPatentsFetched
            )) &&
            totalPatents > 0 && (
              <Container>
                <button
                  onClick={() =>
                    handleApplyFilters(
                      startYear,
                      endYear,
                      selectedSourcesPatents
                    )
                  }
                  className='applyButton'
                >
                  Apply filters
                </button>
              </Container>
            )}
        </div>
      </section>
    </>
  );
};

export default FiltersPatents;
