import { SkeletonTypes } from './types';

import './styles.css';

// Switch with all types of skeleton
const renderSkeletonByType = (type) => {
	switch (type) {
		case SkeletonTypes.FILTER:
			return (
				<div className='blog-post o-media'>
					<div className='o-media__body'>
						<div className='o-vertical-spacing'>
							<h3 className='blog-post__headline'>
								<span className='skeleton-box' style={{ width: '55%' }} />
							</h3>
							<p>
								<span className='skeleton-box' style={{ width: '80%' }} />
								<span className='skeleton-box' style={{ width: '90%' }} />
								<span className='skeleton-box' style={{ width: '83%' }} />
								<span className='skeleton-box' style={{ width: '80%' }} />
							</p>
							<div className='blog-post__meta'>
								<span className='skeleton-box' style={{ width: 70 }} />
							</div>
						</div>
					</div>
				</div>
			);
		case SkeletonTypes.MEDIA_RESOURCE_CARD:
			return (
				<div className='media-resource-skeleton-card'>
					<div className='blog-post o-media'>
						<div className='o-media__body'>
							<div className='o-vertical-spacing'>
								<h3 className='blog-post__headline'>
									<span className='skeleton-box' />
								</h3>
								<div className='skeleton-body'>
									<span className='skeleton-box' style={{ width: '80%' }} />
									<span className='skeleton-box' style={{ width: '90%' }} />
									<span className='skeleton-box' style={{ width: '83%' }} />
								</div>
								<div className='blog-post__meta'>
									<span className='skeleton-box' style={{ width: 70 }} />
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		case SkeletonTypes.NEWS_CARD:
			return (
				<div className='news-skeleton-card'>
					<div className='blog-post o-media'>
						<div className='o-media__body'>
							<div className='o-vertical-spacing'>
								<h3 className='blog-post__headline'>
									<span className='skeleton-box' />
								</h3>
								<div className='skeleton-body'>
									<span
										className='skeleton-box skeleton-body__title'
										style={{ width: '80%' }}
									/>
									<span className='skeleton-box' style={{ width: '90%' }} />
									<span className='skeleton-box' style={{ width: '83%' }} />
									<span
										className='skeleton-box skeleton-body__date'
										style={{ width: '50%' }}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		case SkeletonTypes.FAVORITE_CARD:
			return (
				<div
					className='containerCard'
					style={{
						padding: '1rem',
						width: '275px',
						height: '94px',
						minHeight: '94px',
					}}
				>
					<div className='blog-post o-media'>
						<div className='o-media__body'>
							<div
								className='o-vertical-spacing'
								style={{ display: 'flex', position: 'relative', top: '0.2rem' }}
							>
								<div className='image' style={{ width: '60px' }}>
									<span
										className='skeleton-box'
										style={{
											width: '60px',
											height: '60px',
											borderRadius: '10px',
										}}
									/>
								</div>
								<div
									className='content'
									style={{
										width: '80%',
										marginTop: '0.5rem',
										paddingLeft: '1rem',
									}}
								>
									<h3 className='blog-post__headline'>
										<span
											className='skeleton-box'
											style={{ width: '90%', borderRadius: '5px' }}
										/>
									</h3>
									<p>
										<span
											className='skeleton-box'
											style={{ width: '30%', borderRadius: '5px' }}
										/>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		case SkeletonTypes.LIBRARY_CARD:
		default:
			return (
				<div className='containerCard'>
					<div className='blog-post o-media'>
						<div className='o-media__body'>
							<div className='o-vertical-spacing'>
								<h3 className='blog-post__headline'>
									<span className='skeleton-box' style={{ width: '55%' }} />
								</h3>
								<p>
									<span className='skeleton-box' style={{ width: '80%' }} />
									<span className='skeleton-box' style={{ width: '90%' }} />
									<span className='skeleton-box' style={{ width: '83%' }} />
									<span className='skeleton-box' style={{ width: '80%' }} />
								</p>
								<div className='blog-post__meta'>
									<span className='skeleton-box' style={{ width: 70 }} />
								</div>
							</div>
						</div>
					</div>
				</div>
			);
	}
};

export const LoadingSkeleton = ({ type }) => {
	return <>{renderSkeletonByType(type)}</>;
};
