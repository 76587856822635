export const autenticationURL = (LMS, institutionCode) => {
	const getInstitutionData = (code) => {
		switch (code) {
			case 'CMVC':
				return {
					domain: 'https://compumed.instructure.com',
					redirect: 'https://olib.compumed.edu',
					clientID: process.env.REACT_APP_CLIENTID_CMVC_CANVAS,
				};
			case 'ASP':
				return {
					domain: 'https://aspatria.instructure.com',
					redirect: 'https://asp-library.aquinasnetwork.tech',
					clientID: process.env.REACT_APP_CLIENTID_ASP,
				};
			case 'DOINS':
				return {
					domain: 'https://dominiuminstitute.instructure.com',
					redirect: 'https://library-dominiuminstitute.aquinasnetwork.tech',
					clientID: process.env.REACT_APP_CLIENTID_DOINS,
				};
			case 'UTH':
				return {
					domain: 'https://uthflorida.instructure.com',
					redirect: 'https://library.uthfloridauniversity.com',
					clientID: process.env.REACT_APP_CLIENTID_UTH,
				};
			case 'HCAS':
				return {
					domain: 'https://hcas.instructure.com',
					redirect: 'https://hcas-library.aquinasnetwork.tech',
					clientID: process.env.REACT_APP_CLIENTID_HCAS,
				};
			case 'CMVC_moodle':
				return {
					domain: 'https://moodle.amhealthinstitute.com',
					clientID: process.env.REACT_APP_CLIENTID_CMVC_MOODLE,
				};
			case 'AMHEALTH':
				return {
					domain: 'https://moodle.amhealthinstitute.com',
					clientID: process.env.REACT_APP_CLIENTID_AMHEALTH,
				};
			case 'MACKENZIE':
				return {
					domain: 'https://campus.mackenzieonline.university',
					clientID: process.env.REACT_APP_CLIENTID_MACKENZIE,
				};
			case 'GAPAT':
				return {
					domain: 'https://gapat.net/Mood',
					clientID: process.env.REACT_APP_CLIENTID_GAPAT,
				};
			case 'UNAD':
				return {
					domain: 'https://ahimiami.us/Repository2024',
					clientID: process.env.REACT_APP_CLIENTID_UNAD,
				};
			case 'SUMMA':
				return {
					domain: 'https://centro-virtual.com/campus',
					clientID: process.env.REACT_APP_CLIENTID_SUMMA,
				};
			case 'AHIS':
				return {
					domain: 'https://myelearning.ahimiami.com',
					clientID: process.env.REACT_APP_CLIENTID_AHIS,
				};
			case 'AHIS_2':
				return {
					domain: 'https://elearning.ahimiami.com',
					clientID: process.env.REACT_APP_CLIENTID_AHIS_2,
				};
			case 'SAU':
				return {
					domain: 'https://classroom.sabal.university',
					clientID: process.env.REACT_APP_CLIENTID_SAU,
				};
			case 'FGU':
				return {
					domain: 'https://virtual.floridaglobal.university',
					clientID: process.env.REACT_APP_CLIENTID_FGU,
				};
			case 'ABC':
				return {
					domain: 'https://virtual.americanbusiness.college',
					clientID: process.env.REACT_APP_CLIENTID_ABC,
				};
			case 'CBT':
				return {
					domain: 'https://CBT.instructure.com',
					redirect: 'https://olib.cbt.edu',
					clientID: process.env.REACT_APP_CLIENTID_CBT,
				};
			case 'SABER':
				return {
					domain: 'https://sabercollege.instructure.com',
					redirect: 'https://olib.sabercollege.edu',
					clientID: process.env.REACT_APP_CLIENTID_SABER,
				};
			case 'BRC':
				return {
					domain: 'https://braxtoncollege.instructure.com',
					redirect: 'https://brc-library.aquinasnetwork.tech',
					clientID: process.env.REACT_APP_CLIENTID_BRC,
				};
			case 'LSN':
				return {
					domain: 'https://lsn.instructure.com',
					redirect: 'https://lsn-library.aquinasnetwork.tech',
					clientID: process.env.REACT_APP_CLIENTID_LSN,
				};
			case 'ST':
				return {
					domain: 'https://stratatech.instructure.com',
					redirect: 'https://strata-library.aquinasnetwork.tech',
					clientID: process.env.REACT_APP_CLIENTID_ST,
				};
			case 'MIAMI_BROWARD':
				return {
					domain: 'https://broward.aquinasnetwork.com',
					clientID: process.env.REACT_APP_CLIENTID_MIAMI_BROWARD,
				};
			case 'ALEPH':
				return {
					domain: 'https://moodle.alephuniversity.us',
					clientID: process.env.REACT_APP_CLIENTID_ALEPH,
				};
			case 'IHU':
				return {
					domain: 'https://campus.international-holistic.university',
					clientID: process.env.REACT_APP_CLIENTID_IHU,
				};
			case 'ILUNI':
				return {
					domain: 'https://upanavirtual-test.blackboard.com', // LOGIN URL
					redirect: 'https://iluni-library.aquinasnetwork.tech', // REDIRECT URL
					clientID: process.env.REACT_APP_CLIENTID_ILUNI, // CLIENT ID
				};
			case 'BETAUNI':
				return {
					domain: 'https://betauni.instructure.com',
					redirect: 'https://betauni-library.aquinasnetwork.tech',
					clientID: process.env.REACT_APP_CLIENTID_BETAUNI_CANVAS,
				};
			case 'MTA':
				return {
					domain: 'https://mta.instructure.com',
					redirect: 'https://mta-library.aquinasnetwork.tech',
					clientID: process.env.REACT_APP_CLIENTID_MTA_CANVAS,
				};
			case 'DEV': // ESTE ES SOLO DE PRUEBA
				return {
					domain: 'https://upanavirtual-test.blackboard.com', // LOGIN URL
					redirect: 'https://olib-dev.vercel.app', // REDIRECT URL
					clientID: process.env.REACT_APP_CLIENTID_ILUNI,
				};

			default:
				return {
					domain: 'https://compumed.instructure.com',
					redirect: 'https://olib.compumed.edu',
					clientID: process.env.REACT_APP_CLIENTID_CMVC_CANVAS,
				};
		}
	};

	if (LMS === 'moodle' || LMS === 'moodle 2.9') {
		if (institutionCode === 'CMVC') {
			return `${
				getInstitutionData(institutionCode.concat('_moodle')).domain
			}/local/oauth/login.php?client_id=${
				getInstitutionData(institutionCode.concat('_moodle')).clientID
			}&response_type=code`;
		} else {
			return `${
				getInstitutionData(institutionCode).domain
			}/local/oauth/login.php?client_id=${
				getInstitutionData(institutionCode).clientID
			}&response_type=code`;
		}
	} else if (LMS === 'canvas') {
		return `${
			getInstitutionData(institutionCode).domain
		}/login/oauth2/auth?client_id=${
			getInstitutionData(institutionCode).clientID
		}&response_type=code&redirect_uri=${
			getInstitutionData(institutionCode).redirect
		}/&scope=url:GET|/api/v1/users/:user_id/profile`;
	} else if (LMS === 'digitalocean') {
		if (institutionCode === 'MIAMI_BROWARD') {
			return `https://cloud.digitalocean.com/v1/oauth/authorize?client_id=${
				getInstitutionData(institutionCode).clientID
			}&redirect_uri=${
				getInstitutionData(institutionCode).domain
			}&response_type=code`;
		}
	} else if (LMS === 'blackboard') {
		const institution = getInstitutionData(institutionCode);

		if (institutionCode === 'ILUNI') {
			return `${institution.domain}/learn/api/public/v1/oauth2/authorizationcode?response_type=code&client_id=${institution.clientID}&redirect_uri=${institution.redirect}`;
		}
		if (institutionCode === 'DEV') {
			// SOLO DEV
			return `${institution.domain}/learn/api/public/v1/oauth2/authorizationcode?response_type=code&client_id=${institution.clientID}&redirect_uri=${institution.redirect}`;
		}
	}
};
