import img1 from '../Images/AmericanLife/1.png';
import img2 from '../Images/AmericanLife/2.png';
import img4 from '../Images/AmericanLife/4.png';
import img5 from '../Images/AmericanLife/5.png';
import img6 from '../Images/AmericanLife/6.png';
import img7 from '../Images/AmericanLife/7.png';
import img8 from '../Images/AmericanLife/8.png';
import img9 from '../Images/AmericanLife/9.png';
import img10 from '../Images/AmericanLife/10.png';
import img11 from '../Images/AmericanLife/11.png';
import img12 from '../Images/AmericanLife/12.png';
import img13 from '../Images/AmericanLife/13.png';
import img14 from '../Images/AmericanLife/14.png';
import img15 from '../Images/AmericanLife/15.png';
import img16 from '../Images/AmericanLife/16.png';
import img17 from '../Images/AmericanLife/17.png';

export const getVideosAmericanLife = () => {
  const data = [
    {
      title:
        'How to Speak With Confidence: Crash Course Business - Soft Skills',
      image: img1,
      url: 'sjdxUiAJ0UQ',
      duration: '11:11',
      description:
        'Public speaking is not easy for everyone. It can be nerve racking and even scary. But, in this episode of Crash Course Business Soft Skills, Evelyn talks to us about S.U.C.C.E.S and how we can use it to help us be prepared to speak to a group.',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'How to Speak With Confidence: Crash Course Business - Soft Skills',
      image: img2,
      url: '2Si7ah_h32s',
      duration: '10:59',
      description:
        'Most of us struggle with time management. It is normal. But, things need to get done both at work and in your personal life. So, in this episode of Crash Course Business, Evelyn gives us all some ways to structure our checklist and make sure we get our work done while still not forgetting to take care of ourselves.',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'How to Avoid Teamwork Disasters: Crash Course Business - Soft Skills',
      image: img4,
      url: 'fRj7Am63wVc',
      duration: '14:19',
      description: `Group projects have a reputation of being difficult at times. But there are ways to make sure everything from the project to meetings about the project go smoothly. In this episode, Evelyn chats about how we can make sure and avoid dreaded teamwork disasters.`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'What Are Communication Skills? Top 10!',
      image: img5,
      url: 'xQfYiHbAjJo',
      duration: '7:21',
      description: `What are Communication Skills? We'll look at the Top 10 Communication Skills according to employers' surveys. These are the ones they want you to know and my class in the 5 Essential Professional`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Public speaking for beginners',
      image: img6,
      url: 'i5mYphUoOCs',
      duration: '5:51',
      description: `The video looks at being organized and concise, making direct eye contact, using fewer notes to speak more extemporaneous, using simple slides for visual aids, and a confident posture, gestures, and voice.`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Problem Solving: 6 Skills needed to solve any problem',
      image: img7,
      url: 'klAE9ML0XLs',
      duration: '2:19',
      description: `In our world today, every business or individual faces problems sometimes on a daily basis. Since removing obstacles is essential along your business’s path to success, this video shows you 6 skills suggested to help you become an excellent problem solver.`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title: '5 Common Ethical Issues in the Workplace | Leadership Coaching',
      image: img8,
      url: 'OnD_prSrm3Q',
      duration: '6:47',
      description: `In this video, Shelley Benhoff talks about 5 Common Ethical Issues in the Workplace and Leadership Coaching.`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title: '15 Strategies to Improve Your Problem Solving Skills',
      image: img9,
      url: 'kRtdcBfvixE',
      duration: '14:16',
      description: `Alux.com is the largest community of luxury & fine living enthusiasts in the world. We are the #1 online resource for ranking the most expensive things in the world and frequently referenced in publications such as Forbes, USAToday, Wikipedia and many more, as the GO-TO destination for luxury content!`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'How to Ace the Interview: Crash Course Business - Soft Skills',
      image: img10,
      url: 'BssdOyLB2GU',
      duration: '11:30',
      description: `If you’ve got a job interview coming up and you’re feeling a little nervous, don’t worry. If you’re anything like me, you’ve probably seen enough celebrity interviews to know what works and what doesn’t. Sure, it's not as flashy, but you know what we mean! In this episode, Evelyn sits down to chat about how you can ace your next interview.`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'How to Write a Resume Summary - Plus 5 Strong Examples | Indeed Career Tips',
      image: img11,
      url: 'HjNk6MVpWsM',
      duration: '8:47',
      description: `How can you capture a recruiter's attention immediately when they may spend less than 7 seconds reviewing the document? The answer is a resume summary - short paragraph which should tell a brief, compelling story about who you are as a professional. In this video, Jenn, a certified Career Coach, shares 6 key steps to create a resume summary that best communicates your strengths to recruiters.`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Adaptability in the workplace',
      image: img12,
      url: 'rlmX1yWt_SI',
      duration: '1:56',
      description:
        'Adaptability is one of the keys to managing your emotions in the workplace. This short video looks at why it is important, and gives you some tips to help increase your adaptability.',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Adaptability & Flexibility - SkillsUSA',
      image: img13,
      url: '2A1TN9_8Kr0',
      duration: '2:07',
      description:
        'For more information and learning resources please visit: kevinjfleming.com and skillsusa.org',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'What are Resilience and Adaptability Skills',
      image: img14,
      url: 'lDPCPmBY4Pw',
      duration: '3:01',
      description:
        'This video introduces the concepts of resilience and adaptability, in preparation for the PSA Online Course: Developing Resilience and Adaptability.',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'How to Improve Critical Thinking Skills in The Workplace - Executive Coaching',
      image: img15,
      url: 'qtreTIcqERY',
      duration: '14:19',
      description: `Do you want to improve your critical thinking skills in the workplace? Learn and develop this disciplined process through Dr. Grace Lee's executive coaching program`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title: '5 Types of Conflict in the Workplace and How To Handle Them',
      image: img16,
      url: 'o7-bbd1F9bk',
      duration: '14:46',
      description: `Here are the five most common types of conflict in the workplace. Along with two tips for dealing with the most common (and complex)... personality conflicts at work`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Developing Critical Thinking Skills',
      image: img17,
      url: 'Iwnwq3buLjI',
      duration: '2:17',
      description: `Developing Critical Thinking Skills.`,
      language: 'EN',
      type: 'youtube',
    },
  ];

  return data;
};
