import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Slices
import {
  setPromptFetched,
  setResponse,
  setIsLoading,
  setPrompt,
} from '../../app/features/iaSlice.js';
// Components
import IAInit from '../../Components/IAInit/index.jsx';
import Info from '../../Components/Info/index.jsx';
import Container from '../../Components/Container/index.jsx';
import Delete from '../../Components/Delete/index.jsx';
import IASearchBar from '../../Components/IASearchBar/index.jsx';
// Services
import { getIASearch } from '../../services/getIASearch.js';
// Utils
import { centerTo } from '../../utils/centerTo.js';
// Images
import iaIcon from '../../Images/Login/Modal/iaIcon.svg';
import logoAquinas from '../../Images/logo/logo_OlibBlack.svg';
import iconRelated from '../../Images/ia/iconRelatedResources.svg';
import loadingAquino from '../../Images/Loading/aquinoLoadingSmall.gif';
// Icons
import { FaRegCopy } from 'react-icons/fa6';
import { GrPowerCycle } from 'react-icons/gr';
import { IoIosArrowBack } from 'react-icons/io';
// Styles
import './AIPage.css';

const IAPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { promptFetched, response, isLoading } = useSelector(
    (state) => state.ia
  );

  const [searchBarHeight, setSearchBarHeight] = useState(0);

  const getHeight = () => {
    if (searchBarHeight + 240 < 510) {
      const result = searchBarHeight + 240;
      return `${result}px`;
    } else {
      return '510px';
    }
  };

  const getAuxQuestions = (aux) => {
    if (aux?.auxiliary_questions?.length > 0) {
      return (
        <div className='auxContainer'>
          {aux.auxiliary_questions?.map((element, i) => {
            return (
              <details key={i} open>
                <summary className='auxQuestion'>{element.question}</summary>
                <p className='auxAnswer'>{element.answer}</p>
              </details>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  };

  const getRelatedLinks = (related) => {
    if (related?.interesting_urls?.length > 0) {
      return (
        <div className='infoResponse_Related'>
          <p>
            <b>Suggested resource links for your search:</b>
          </p>
          <div className='relatedContainer'>
            {related.interesting_urls?.map((element, i) => {
              return (
                <a
                  className='relatedIACard'
                  href={element.url}
                  target='_blank'
                  rel='noreferrer'
                  key={i}
                >
                  <img
                    src={iconRelated}
                    alt='related resource'
                    loading='lazy'
                  />
                  <p>{element.title}</p>
                </a>
              );
            })}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const generateAnotherResponse = (prompt) => {
    const newPrompt = `generate another response about: ${prompt}`;
    dispatch(setIsLoading(true));
    dispatch(setPromptFetched(newPrompt));
    centerTo('loading');
    getIASearch(newPrompt)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setPrompt(''));
          const data = Array.isArray(res.data) ? res.data : [res.data];
          dispatch(setResponse(data));
          dispatch(setIsLoading(false));
          centerTo('answer', response.length);
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch(setResponse([]));
        dispatch(setIsLoading(false));
      });
  };

  const copyAnswer = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Error copying text: ', err);
    }
  };

  if (!promptFetched) {
    return <IAInit setSearchBarHeight={setSearchBarHeight} />;
  } else {
    return (
      <main className='ia'>
        <Container className='iaContainer'>
          <header className='iaHeader'>
            <button
              className='goBackButton'
              onClick={() => navigate('/articles')}
            >
              <IoIosArrowBack />
              <p>Go back</p>
            </button>
            <img
              src={logoAquinas}
              alt='logo Aquino'
              role='button'
              onClick={() => navigate('/')}
            />
            <div className='iaHeaderOptions'>
              <Delete />
              <Info />
            </div>
          </header>
          {promptFetched.length > 0 && (
            <main className='iaMain'>
              <div
                className='info'
                style={{ height: `calc(100vh - ${getHeight()})` }}
              >
                {response.length > 0 &&
                  response?.map((res, i) => {
                    return (
                      <div
                        id={`containerPrompt_${i}`}
                        className='containerPrompt'
                        key={i}
                      >
                        <div className='prompt'>
                          <p>{res.keywords}</p>
                        </div>
                        <div className='infoResponseContainer'>
                          <div className='infoResponse'>
                            <div className='infoResponse__info'>
                              <div>
                                <img src={iaIcon} alt='Aquino' />
                              </div>

                              <p>
                                Here is your answer about:{' '}
                                <span className='promtFetched'>
                                  {res.keywords.replace(
                                    'generate another response about: ',
                                    ''
                                  )}
                                </span>
                              </p>
                            </div>
                            <div className='infoResponse__answer'>
                              <p
                                className='answerContainer'
                                dangerouslySetInnerHTML={{
                                  __html: res.principal_answer
                                    .replace(
                                      /\*\*(.*?)\*\*/g,
                                      '<i className="test"> ● $1</i>'
                                    ) // Reemplaza **Texto** por <li>Texto</li>
                                    .replace(/\* /g, '<br><br>'), // Reemplaza * por <br>
                                }}
                              />
                            </div>

                            {res?.auxiliary_questions?.length > 0 && (
                              <div className='infoResponse_Related'>
                                <p>
                                  <b>
                                    These are some questions related to this
                                    session:
                                  </b>
                                </p>
                                {getAuxQuestions(res)}
                              </div>
                            )}

                            {res?.interesting_urls?.length > 0 &&
                              getRelatedLinks(res)}
                          </div>
                          <div className='infoIAView'>
                            <div className='lineIA'></div>

                            <p>
                              <b>
                                I hope the information has been helpful in your
                                search!
                              </b>
                            </p>
                            <div className='iaOptionSearch'>
                              <button
                                onClick={() => copyAnswer(res.principal_answer)}
                              >
                                <FaRegCopy />
                                Copy
                              </button>
                              <button
                                onClick={() =>
                                  generateAnotherResponse(res.keywords)
                                }
                              >
                                <GrPowerCycle />
                                Generate another response
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {isLoading && (
                  <div id='aquinoLoading' className='loadingIAContainer'>
                    <img src={loadingAquino} alt='loading' />
                  </div>
                )}
              </div>
            </main>
          )}

          <div className='searchBarIAContainer'>
            <IASearchBar setSearchBarHeight={setSearchBarHeight} />
            <p>
              This assistant can provide automated responses. Always verify key
              information before making decisions.
            </p>
          </div>
        </Container>
      </main>
    );
  }
};

export default IAPage;
