import Container from '../../Components/Container';
import FavoriteCard from '../../Components/FavoriteCard';
import './myFavoritesPage.css';
import { useMyFavoritesPage } from './useMyFavoritesPage';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import closeIcon from '../../Images/Login/Modal/close-circle.svg';
import { formatKebabCaseToSentence } from '../../utils/formatKebabCaseToSentence';
import { LoadingSkeleton } from '../../Components/LoadingSkeleton';
import { SkeletonTypes } from '../../Components/LoadingSkeleton/types';
import { Fragment } from 'react';

const MyFavoritesPage = () => {
	const {
		handleShowDeleteModal,
		handleCloseDeleteModal,
		showDeleteModal,
		favorites,
		getImage,
		loading,
		handleDeleteCategory,
		selectedCategory,
		user_email,
	} = useMyFavoritesPage();

	return (
		<>
			<Modal
				isOpen={showDeleteModal}
				contentLabel='Delete favorites modal'
				style={{
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						transform: 'translate(-50%, -50%)',
						maxWidth: '290px',
						maxHeight: '80%',
						borderRadius: '5px',
						padding: '2rem',
						position: 'relative',
					},
					overlay: {
						background: '#00000087',
					},
				}}
			>
				<button
					className='close-modal__delete'
					onClick={handleCloseDeleteModal}
				>
					<img
						src={closeIcon}
						className='close-modal__delete--image'
						alt='close modal'
					/>
				</button>
				<p className='delete-favorite__title'>
					Are you sure you want to delete this item?
				</p>
				<div className='delete-favorite__buttons'>
					<button
						className='delete-favorite__yes'
						onClick={() => handleDeleteCategory(user_email, selectedCategory)}
					>
						Yes, delete
					</button>
					<button
						className='delete-favorite__no'
						onClick={handleCloseDeleteModal}
					>
						No, cancel
					</button>
				</div>
			</Modal>

			<div className='favorites-main'>
				<Container>
					<div className='favorites-container'>
						<div className='favorites-container_title'>
							Collections ({favorites?.length})
						</div>

						{!loading && !favorites?.length && (
							<>
								<p>No favorites yet. Start adding your top picks!</p>
							</>
						)}

						<div className='favorite-list'>
							{loading &&
								Array.from({ length: 3 }).map((_, index) => (
									<LoadingSkeleton
										type={SkeletonTypes.FAVORITE_CARD}
										key={index}
									/>
								))}

							{loading &&
								Array.from({ length: 3 }).map((_, index) => (
									<LoadingSkeleton
										type={SkeletonTypes.FAVORITE_CARD}
										key={index}
									/>
								))}

							{!loading &&
								favorites?.map((favorite) => (
									<Fragment key={favorite.type}>
										<Link to={`/favorites?type=${favorite.type}`}>
											<FavoriteCard
												title={formatKebabCaseToSentence(favorite.type)}
												articlesNumber={favorite.counter}
												image={getImage(favorite.type)}
												onDelete={() => handleShowDeleteModal(favorite.type)}
											/>
										</Link>
									</Fragment>
								))}
						</div>
					</div>
				</Container>
			</div>
		</>
	);
};

export default MyFavoritesPage;
