import React, { useEffect } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Components
import CardVideo from '../../Components/CardVideo';
import Container from '../../Components/Container';
import Pagination from '../../Components/Pagination';
import SummarySearch from '../../Components/SummarySearch';
// Hooks
import { useScrollToTop } from '../../hooks/useScrollToTop';
// Services
import { getLibraryVideos } from '../../services/getLibraryVideos';
// Icons
import { IoMdCloseCircle } from 'react-icons/io';
import {
  setVideos,
  setPage,
  setQueryLibraryVideos,
  setQueryLibraryVideosSearched,
} from '../../app/features/libraryVideosSlice';
// Styles
import './VideoLibrary.css';

const VideoLibrary = () => {
  const dispatch = useDispatch();
  const { handleScrollToTop } = useScrollToTop();

  useEffect(() => {
    dispatch(setVideos(getLibraryVideos('')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { videos, page, queryLibraryVideosSearched } = useSelector(
    (state) => state.libraryVideos
  );

  // Pagination
  const itemsPerPage = 12;
  const totalVideos = videos.length;
  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = videos.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(totalVideos / itemsPerPage);

  // Funcion de cambio de paginacion
  const handlePageChange = (newPage) => {
    handleScrollToTop(0);
    if (newPage > page) {
      if (
        page < totalPages ||
        (Math.ceil(videos?.length / itemsPerPage) > 1 && totalPages === 0)
      ) {
        dispatch(setPage(newPage));
      }
    } else {
      if (page > 1) {
        dispatch(setPage(newPage));
      }
    }
  };

  const clearSearch = () => {
    dispatch(setVideos(getLibraryVideos('')));
    dispatch(setQueryLibraryVideos(''));
    dispatch(setQueryLibraryVideosSearched(''));
    dispatch(setPage(1));
  };

  return (
    <main className='VideoLibrary'>
      <Container>
        {queryLibraryVideosSearched && (
          <div className='VideoLibrarySummarySearch newsQueryContentInfo'>
            <SummarySearch
              total={totalVideos}
              term={queryLibraryVideosSearched}
            />
            <button onClick={clearSearch}>
              <IoMdCloseCircle className='newsQueryContentIcon' />
            </button>
          </div>
        )}
        <section className='VideoLibraryContainer'>
          {currentItems?.map((video, i) => {
            return (
              <CardVideo
                key={i}
                image={video.image}
                time={video.duration}
                title={video.title}
                description={video.description}
                url={video.url}
                language={video.language}
              />
            );
          })}
        </section>
        <Pagination
          pageShowed={page}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </Container>
    </main>
  );
};

export default VideoLibrary;
