import React, { useEffect, useState } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Images
import PlayButtonImage from '../../Images/yt-play-red.svg';
import PlaceholderImage from '../../Images/placeholder-image.png';
// Styles
import './mediaCard.css';
import FavoriteButton from '../FavoriteButton';
import { FavoriteCategory } from '../../Views/FavoriteDetail/types';
import {
	getFavoriteByUrl,
	handleDeleteFavorite,
} from '../FavoriteButton/utils';

const MediaCard = ({
	image,
	time,
	title,
	description,
	url,
	language,
	type = 'youtube',
	favoriteType = 'favorite', // favorite | detail
	favoriteCategory = FavoriteCategory.MEDIA,
	onDeleteFavorite,
	showFavoriteButton = true,
}) => {
	const dispatch = useDispatch();
	const [favoriteStatus, setFavoriteStatus] = useState(false);
	const favorites = useSelector((state) => state.favorites.favorites);
	const { user_email } = useSelector((state) => state.user);

	useEffect(() => {
		const hasFavorite = getFavoriteByUrl(url, favorites);

		setFavoriteStatus(!!hasFavorite);
	}, [favorites, url]);
	return (
		<>
			<div
				className='media-card-component'
				onClick={() => window.open(url, '_blank')}
			>
				<div
					className='media-card__image'
					style={{ position: 'relative', overflow: 'hidden' }}
				>
					<div
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							backgroundImage: `url(${image ? image : PlaceholderImage})`,
							backgroundSize: 'cover',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							filter: 'blur(8px)',
							zIndex: 1,
						}}
					/>

					<img
						src={image ? image : PlaceholderImage}
						alt=''
						style={{ position: 'relative', zIndex: 2 }}
						className='media-card__blurred-image'
					/>

					<img
						src={PlayButtonImage}
						alt='play button'
						className='play-button__btn'
					/>
					<div className='media-card__time'>{time}</div>
				</div>
				<div className='media-card__content'>
					<div className='media-card__title-up'>
						<div className='media-card__title'>{title}</div>
						{showFavoriteButton && (
							<FavoriteButton
								isAbsolute={false}
								type={favoriteType}
								category={favoriteCategory}
								favoriteData={{
									description,
									favoriteCategory,
									language,
									time,
									title,
									type,
									url,
									image,
								}}
								isFavorite={favoriteStatus}
								onDeleteFavorite={() =>
									handleDeleteFavorite(
										url,
										favorites,
										favoriteType,
										user_email,
										dispatch,
										onDeleteFavorite
									)
								}
								url={url}
							/>
						)}
					</div>
					<div className='media-card__description'>{description}</div>
				</div>
			</div>
		</>
	);
};

export default MediaCard;
