import React, { useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Components
import CardVideo from '../../Components/CardVideo';
import Container from '../../Components/Container';
import Pagination from '../../Components/Pagination';
import ThumbnailEn from '../../Images/thumbnail-eng.jpg';
import ThumbnailEs from '../../Images/thumbnail-es.jpg';
// Hooks
import { useScrollToTop } from '../../hooks/useScrollToTop';
// Services
import { getVideosTutorials } from '../../services/getVideosTutorials';
// Syles
import './TutorialsPage.css';
import { FavoriteCategory } from '../FavoriteDetail/types';
import { useAuth } from '../../hooks/useAuth';

const TutorialsPage = () => {
	const { isAuth } = useAuth();
	// Hookks
	const { handleScrollToTop } = useScrollToTop();

	// Slices
	const { institutionCode } = useSelector((state) => state.institution);

	// Variables
	const [pageShowedTutorials, setPageShowedTutorials] = useState(1);

	const dataVideos = getVideosTutorials(institutionCode);

	// Pagination
	const itemsPerPage = 12;
	const totalNews = dataVideos.length;
	const indexOfLastItem = pageShowedTutorials * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = dataVideos.slice(indexOfFirstItem, indexOfLastItem);
	const totalPages = Math.ceil(totalNews / itemsPerPage);

	// Funcion de cambio de paginacion
	const handlePageChange = (newPage) => {
		handleScrollToTop(0);
		if (newPage > pageShowedTutorials) {
			if (
				pageShowedTutorials < totalPages ||
				(Math.ceil(getVideosTutorials()?.length / itemsPerPage) > 1 &&
					totalPages === 0)
			) {
				setPageShowedTutorials(newPage);
			}
		} else {
			if (pageShowedTutorials > 1) {
				setPageShowedTutorials(newPage);
			}
		}
	};

	return (
		<main className='Tutorials'>
			<Container>
				<div className='banner-tutorial-section'>
					<div className='content-banner-tutorial'>
						<div className='title-banner-tutorial'>
							<b>Getting Started</b> with OLIB
						</div>
						<p className='description-banner-tutorial'>
							Learn to use OLIB with these two interactive videos, available in
							English and Spanish. These are the only tutorials you need to
							navigate the platform, search for resources, and customize your
							experience efficiently.
						</p>
						<p className='helper-banner-tutorial'>
							Make the most of OLIB and enhance your learning experience.
						</p>
					</div>
					<div className='videos-banner-tutorial'>
						<CardVideo
							image={ThumbnailEn}
							time={'6:00'}
							title={'How can I make the most of Aquinas Online Library?'}
							description={`We understand that getting familiar with a new platform can take time. That's why we've created a detailed interactive video to guide you through all the features Aquinas Online Library has to offer.`}
							url={
								'https://interactivevideo.aquinas.tech/wp-admin/admin-ajax.php?action=h5p_embed&id=6'
							}
							language={'en'}
							type={'external'}
							showFavoriteButton={false}
						/>
						<CardVideo
							image={ThumbnailEs}
							time={'6:00'}
							title={'¿Cómo puedo aprovechar al máximo Aquinas Online Library?'}
							description={`Entendemos que familiarizarse con una nueva plataforma puede llevar tiempo. Por eso, hemos creado un video interactivo detallado para guiarte a través de todas las funcionalidades que Aquinas Online Library tiene para ofrecerte.`}
							url={
								'https://interactivevideo.aquinas.tech/wp-admin/admin-ajax.php?action=h5p_embed&id=5'
							}
							language={'en'}
							type={'external'}
							showFavoriteButton={false}
						/>
					</div>
				</div>
				<section className='AmericanLifeContainer'>
					{currentItems?.map((video, i) => {
						return (
							<CardVideo
								key={i}
								image={video.image}
								time={video.duration}
								title={video.title}
								description={video.description}
								url={video.url}
								language={video.language}
								type={video.type}
								favoriteCategory={FavoriteCategory.TUTORIALS}
								showFavoriteButton={isAuth() ? true : false}
							/>
						);
					})}
				</section>
				<Pagination
					pageShowed={pageShowedTutorials}
					totalPages={totalPages}
					handlePageChange={handlePageChange}
				/>
			</Container>
		</main>
	);
};

export default TutorialsPage;
