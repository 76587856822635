import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
// Redux
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// Components
import NavigationBarModules from '../NavigationBarModules';
// Images
import logoOnlineLibrary from '../../Images/logo/logo_OlibBlack.svg';
import mainPanelIcon from '../../Images/NavigationBar/mainPanelIcon.svg';
// Icons
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdKeyboardArrowLeft } from 'react-icons/md';
// Services
import {
	getSectionsInstitutions,
	getSectionsNoAuth,
	getSectionsWithoutNavbar,
} from '../../services/getSectionsInstitutions';
// Styles
import './NavigationBarResources.css';
import { useAuth } from '../../hooks/useAuth';

const NavigationBarResources = () => {
	const { isAuth } = useAuth();
	const { institutionCode, ebooksAccess } = useSelector(
		(state) => state.institution
	);

	const { pathname } = useLocation();
	const currentlyRoute = pathname.replace('/', '');

	const sectionsMetasearcher = getSectionsInstitutions(
		institutionCode,
		ebooksAccess
	)[0].data;
	const sectionsIndependientList = getSectionsInstitutions(
		institutionCode,
		ebooksAccess
	)[1].data.filter((objeto) => objeto.type === 'independient');

	const independentSections = isAuth()
		? sectionsIndependientList
		: getSectionsNoAuth(sectionsIndependientList);

	// Get sections without nabvar in the view
	const sectionsWithoutNavbar = getSectionsWithoutNavbar();

	const pageList = [
		'articles',
		'books',
		'art',
		'news',
		'patents',
		'dictionary',
		'thesaurus',
		'media',
		'jobs',
		'interesting-links',
		'american-life',
		'video-library',
		'tutorials',
		'my-favorites',
		'favorites',
		'help',
	];

	const [activeBar, setActiveBar] = useState(false);

	if (pageList.includes(currentlyRoute)) {
		return (
			<>
				<div
					onClick={() => setActiveBar(true)}
					className='optionsNavigationBarContainer'
				>
					<button className='optionsNavigationBar'>
						<GiHamburgerMenu />
					</button>
				</div>

				<section
					className={
						activeBar
							? 'navigationBarResources activeBar'
							: 'navigationBarResources'
					}
				>
					<div
						onClick={() => setActiveBar(false)}
						className={
							activeBar
								? 'navigationBarBackground activeBar'
								: 'navigationBarBackground'
						}
					></div>

					<div className='navigationBarResourcesContainer'>
						<div className='containerLogo'>
							<Link to='/'>
								<img src={logoOnlineLibrary} alt='logo online library' />
							</Link>
							<button onClick={() => setActiveBar(false)}>
								<MdKeyboardArrowLeft className='containerLogoClose' />
							</button>
						</div>
						<div className='containerPanel'>
							<div className='panelSections'>
								<div>
									<img
										className='imageMainPanelSections'
										src={mainPanelIcon}
										alt='main panel'
									/>
									<p>
										<b>Main panel</b>
									</p>
								</div>
								{sectionsMetasearcher?.map((section, i) => {
									return (
										<NavigationBarModules
											key={i}
											type={section.type}
											active={currentlyRoute === section.route}
											resource={section}
											metasearcher={true}
											setActiveBar={setActiveBar}
										/>
									);
								})}
							</div>
							<div className='panelSections'>
								<div>
									<p>
										<b>Other Sections</b>
									</p>
								</div>
								{independentSections?.map((section, i) => {
									return (
										<NavigationBarModules
											key={i}
											type={section.type}
											active={
												currentlyRoute === section.route ||
												currentlyRoute === section.childPage // Permite que se muestre activo con paginas hijas como Favoritos > Detalle de favorito
											}
											resource={section}
											metasearcher={false}
											setActiveBar={setActiveBar}
										/>
									);
								})}
							</div>
						</div>
					</div>
				</section>
			</>
		);
	} else if (sectionsWithoutNavbar.includes(currentlyRoute)) {
		return null;
	} else {
		return <div className='optionsNavigationBarContainer'></div>;
	}
};

export default NavigationBarResources;
