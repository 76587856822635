import React from 'react';
// Components
import 'react-alice-carousel/lib/alice-carousel.css';
import AliceCarousel from 'react-alice-carousel';
// Services
import { sendUserSearchInformation } from '../../services/sendUserSearchInformation';
// Utils
import { publicationDate } from '../../utils/publicationDate';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Styles
import './CarouselNews.css';
import { preventBehavior } from '../../utils/preventBehavior';
import { setActiveLoginModal } from '../../app/features/userSlice';

const CarouselNews = ({ news, canExecuteLogger, hasLoginSettingsActive }) => {
	const dispatch = useDispatch();
	const settings = {
		autoPlay: true,
		infinite: true,
		// autoPlayInterval: 8000,
		autoPlayInterval: 800000000,
		animationDuration: 800,
		disableButtonsControls: false,
		disableDotsControls: false,
		keyboardNavigation: true,
		touchMoveDefaultEvents: true,
		showArrows: true,
		responsive: {
			0: {
				items: 1,
			},
			950: {
				items: 2,
				itemsFit: 'contain',
			},
			1600: {
				items: 3,
				itemsFit: 'contain',
			},
		},
	};

	const { user_email } = useSelector((state) => state.user);
	const { institutionCode } = useSelector((state) => state.institution);

	const handleSeeMore = (title, resource_origin, url) => {
		sendUserSearchInformation(
			title,
			resource_origin,
			url,
			'news',
			user_email,
			institutionCode
		);
		window.open(url, '_blank');
	};

	const handleClickCard = (
		e,
		title,
		resource_origin,
		url,
		canExecuteLogger
	) => {
		if (!canExecuteLogger && hasLoginSettingsActive) {
			preventBehavior(e);
			dispatch(setActiveLoginModal(true));
			return;
		}
		handleSeeMore(title, resource_origin, url);
		return;
	};

	return (
		<AliceCarousel {...settings}>
			{news?.length > 0 &&
				news?.map((element, i) => (
					<div key={i} className='newsCarousel'>
						<div className='newsCarouselImageContainer'>
							<img
								className={
									element.is_default_image
										? 'NoDefaultNews__image'
										: 'defaultNews__image'
								}
								src={element.image_url}
								alt='News'
							/>
						</div>
						<div className='newsCarouselInformation'>
							<div className='newsCarouselInformationDate'>
								<p>NEW</p>
								{element.publication_date && (
									<p>Published: {publicationDate(element.publication_date)}</p>
								)}
							</div>

							<h3 className='newsCarouselTitle'>{element.title}</h3>
							<p className='newsCarouselText'>{element.description}</p>
							<button
								onClick={(e) =>
									handleClickCard(
										e,
										element.title,
										element.source,
										element.url,
										canExecuteLogger
									)
								}
								className='newsCarouselButton'
							>
								View more
							</button>
						</div>
					</div>
				))}
		</AliceCarousel>
	);
};

export default CarouselNews;
