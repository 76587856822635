import React from 'react';
// Components
import Login from '../Login';
import ArtSearchBar from '../ArtSearchBar';
import Metasearcher from '../Metasearcher';
import JobsSearchBar from '../JobsSearchBar';
import LibraryVideoSearchBar from '../LibraryVideoSearchBar';
// Redux
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
// Services
import {
	getSectionsInstitutions,
	getSectionsWithoutNavbar,
} from '../../services/getSectionsInstitutions';
// Styles
import './SearchBar.css';
import HeartIconBig from '../../Images/Login/Modal/heartIconBig.svg';
import { useAuth } from '../../hooks/useAuth';

const SearchBar = () => {
	const { pathname } = useLocation();
	const currentlyRoute = pathname.replace('/', '');
	const { isAuth } = useAuth();
	// Get sections without nabvar in the view
	const sectionsWithoutNavbar = getSectionsWithoutNavbar();

	// Slices
	const { institutionCode, ebooksAccess } = useSelector(
		(state) => state.institution
	);
	const sectionsMetasearcher = getSectionsInstitutions(
		institutionCode,
		ebooksAccess
	)[0].data.map((objeto) => objeto.route);

	const handleMetasearchBar = () => {
		if (sectionsMetasearcher.includes(currentlyRoute)) {
			return <Metasearcher />;
		} else if (currentlyRoute === 'art') {
			return <ArtSearchBar />;
		} else if (currentlyRoute === 'jobs') {
			return <JobsSearchBar />;
		} else if (currentlyRoute === 'video-library') {
			return <LibraryVideoSearchBar />;
		} else {
			return null;
		}
	};

	if (!sectionsWithoutNavbar.includes(currentlyRoute)) {
		return (
			<div className='searchBar'>
				<div className='searchBarContainer'>
					{handleMetasearchBar()}
					<div className='metasearcherLogin'>
						{isAuth() && (
							<div className='my-favorites-btn'>
								<Link to='/my-favorites'>
									<button>
										<img src={HeartIconBig} alt='heart icon' />
									</button>
								</Link>
							</div>
						)}
						<Login />
					</div>
				</div>
			</div>
		);
	}
};

export default SearchBar;
