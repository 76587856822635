/**
 * Converts a kebab-case string to a formatted sentence.
 *
 * @param {string} str - The kebab-case string.
 * @returns {string} - The formatted sentence with proper capitalization.
 */
export const formatKebabCaseToSentence = (str) => {
	if (!str) return '';

	return str
		.replace(/-/g, ' ') // Replace hyphens with spaces
		.replace(/^\w/, (c) => c.toUpperCase()); // Capitalize first letter
};
