import { combineReducers, configureStore } from '@reduxjs/toolkit';
// import { createMigrate } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

//Reducers / Slices
import exampleSlice from './features/exampleSlice';
import persistReducer from 'redux-persist/es/persistReducer';
import dictionarySlice from './features/dictionarySlice';
import librarySlice from './features/librarySlice';
import newsSlice from './features/newsSlice';
import authSlice from './features/authSlice';
import institutionSlice from './features/institutionSlice';
import interestingLinksSlice from './features/interestingLinksSlice';
import userSlice from './features/userSlice';
import metasearchSlice from './features/metasearchSlice';
import patentsSlice from './features/patentsSlice';
import jobsSlice from './features/jobsSlice';
import multimediaSlice from './features/multimediaSlice';
import artSlice from './features/artSlice';
import booksSlice from './features/booksSlice';
import iaSlice from './features/iaSlice.js';
import libraryVideosSlice from './features/libraryVideosSlice.js';
import favoriteSlice from './features/favoriteSlice.js';

const persistConfig = {
	key: 'root',
	versin: 0.1,
	storage,
	// migrate: createMigrate
	stateReconciler: autoMergeLevel2,
	blacklist: ['art', 'jobs'],
};

const reducers = combineReducers({
	example: exampleSlice,
	dictionary: dictionarySlice,
	library: librarySlice,
	news: newsSlice,
	auth: authSlice,
	institution: institutionSlice,
	interestingLinks: interestingLinksSlice,
	user: userSlice,
	metasearch: metasearchSlice,
	patents: patentsSlice,
	jobs: jobsSlice,
	multimedia: multimediaSlice,
	art: artSlice,
	books: booksSlice,
	ia: iaSlice,
	libraryVideos: libraryVideosSlice,
	favorites: favoriteSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
	reducer: persistedReducer,
	devTools: true,
	middleware: [thunk],
});
