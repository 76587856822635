import Container from '../../Components/Container';
import { FaChevronLeft } from 'react-icons/fa';
import './favoriteDetailPage.css';
import Card from '../../Components/Card';

import { Link } from 'react-router-dom';
import {
	canUseFourColumnsGrid,
	canUseThreeColumnsGrid,
	canUseTwoColumnsGrid,
} from './utils';
import { FavoriteCategory } from './types';
import CardNewsSearched from '../../Components/CardNewsSearched';
import CardInterestingLinksHome from '../../Components/CardInterestingLinksHome';
import CardVideo from '../../Components/CardVideo';
import { useFavoriteDetailPage } from './useFavoriteDetailPage';
import { Fragment } from 'react';
import Modal from 'react-modal';
import closeIcon from '../../Images/Login/Modal/close-circle.svg';
import Pagination from '../../Components/Pagination';
import MediaCard from '../../Components/MediaCard';

const FavoriteDetail = () => {
	const {
		type,
		getTitle,
		favorites,
		handleDeleteFavorite,
		showDeleteModal,
		handleCloseDeleteModal,
		selectedFavorite,
		handleShowDeleteModal,
		totalPages,
		currentPage,
		setCurrentPage,
	} = useFavoriteDetailPage();

	return (
		<>
			<Modal
				isOpen={showDeleteModal}
				contentLabel='Delete favorites modal'
				style={{
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						transform: 'translate(-50%, -50%)',
						maxWidth: '290px',
						maxHeight: '80%',
						borderRadius: '5px',
						padding: '2rem',
						position: 'relative',
					},
					overlay: {
						background: '#00000087',
					},
				}}
			>
				<button
					className='close-modal__delete'
					onClick={handleCloseDeleteModal}
				>
					<img
						src={closeIcon}
						className='close-modal__delete--image'
						alt='close modal'
					/>
				</button>
				<p className='delete-favorite__title'>
					Are you sure you want to delete this item?
				</p>
				<div className='delete-favorite__buttons'>
					<button
						className='delete-favorite__yes'
						onClick={() => handleDeleteFavorite(selectedFavorite)}
					>
						Yes, delete
					</button>
					<button
						className='delete-favorite__no'
						onClick={handleCloseDeleteModal}
					>
						No, cancel
					</button>
				</div>
			</Modal>
			<div className='favorites-main'>
				<Container>
					<div className='favorites-container'>
						<div className='back-btn'>
							<Link to={'/my-favorites'}>
								<button className='d-flex align-items-center'>
									<FaChevronLeft />
									<span className='px-2'> Back</span>
								</button>
							</Link>
						</div>

						<div className='favorites-container_title '>{getTitle(type)}</div>
						<div className='favorite-detail__list'>
							{canUseThreeColumnsGrid(type) && (
								<div className='favorite-detail-grid  favorite-grid-3-column'>
									{favorites?.map((favorite, i) => {
										const { type, favorite: fav } = favorite;

										// Common Card component for standard categories
										const standardCardCategories = [
											FavoriteCategory.ARTICLES,
											FavoriteCategory.PATENTS,
											FavoriteCategory.BOOKS,
										];

										if (standardCardCategories.includes(type)) {
											return (
												<Card
													url={fav.url}
													showFavoriteButton={true}
													key={favorite.id}
													title={fav.title}
													authors={fav.authors}
													abstract={fav.abstract}
													type={fav.type}
													publication_date={fav.publication_date}
													favoriteType='detail'
													onDeleteFavorite={() =>
														handleShowDeleteModal(favorite.id)
													}
												/>
											);
										}

										// Special cases for different card types
										const favoriteComponents = {
											[FavoriteCategory.INTERESTING_LINKS]: (
												<CardInterestingLinksHome
													key={favorite.id}
													showFavoriteButton={true}
													title={fav.title}
													image_url={fav.image_url}
													url={fav.url}
													description={fav.description}
													type={fav.resource}
													resource_origin={fav.resource_origin}
													favoriteType='detail'
													onDeleteFavorite={() => {
														handleShowDeleteModal(favorite.id);
													}}
												/>
											),
											[FavoriteCategory.SOFT_SKILLS]: (
												<CardVideo
													key={favorite.id}
													showFavoriteButton={true}
													image={fav.image}
													time={fav.time}
													title={fav.title}
													description={fav.description}
													url={fav.url}
													type={fav.type}
													language={fav.language}
													favoriteType='detail'
													onDeleteFavorite={() => {
														handleShowDeleteModal(favorite.id);
													}}
													customUrl={`https://www.youtube.com/embed/${fav.url}`}
												/>
											),
											[FavoriteCategory.TUTORIALS]: (
												<CardVideo
													key={favorite.id}
													image={fav.image}
													showFavoriteButton={true}
													time={fav.time}
													title={fav.title}
													type={fav.type}
													description={fav.description}
													url={fav.url}
													language={fav.language}
													favoriteType='detail'
													onDeleteFavorite={() => {
														handleShowDeleteModal(favorite.id);
													}}
												/>
											),
										};

										return (
											<Fragment key={i}>
												{favoriteComponents[type] || null}
											</Fragment>
										);
									})}
								</div>
							)}

							{canUseTwoColumnsGrid(type) && (
								<div className='favorite-detail-grid  favorite-grid-2-column'>
									{favorites?.map((favorite, i) => {
										return (
											<Fragment key={favorite.id}>
												{type === FavoriteCategory.NEWS && (
													<CardNewsSearched
														showFavoriteButton={true}
														title={favorite.favorite.title}
														description={favorite.favorite.description}
														url={favorite.favorite.url}
														image_url={favorite.favorite.image_url}
														publication_date={
															favorite.favorite.publication_date
														}
														resource_origin={favorite.favorite.resource_origin}
														favoriteType='detail'
														onDeleteFavorite={() => {
															handleShowDeleteModal(favorite.id);
														}}
													/>
												)}
											</Fragment>
										);
									})}
								</div>
							)}

							{canUseFourColumnsGrid(type) && (
								<div className='favorite-detail-grid favorite-grid-4-column'>
									{favorites?.map((favorite, i) => {
										return (
											<Fragment key={favorite.id}>
												<MediaCard
													key={favorite.id}
													showFavoriteButton={true}
													image={favorite.favorite.image}
													time={favorite.favorite.time}
													title={favorite.favorite.title}
													description={favorite.favorite.description}
													url={favorite.favorite.url}
													language={favorite.favorite.language}
													// type='new_tab'
													type={favorite.favorite.type}
													favoriteType='detail'
													onDeleteFavorite={() => {
														handleShowDeleteModal(favorite.id);
													}}
												/>
											</Fragment>
										);
									})}
								</div>
							)}
						</div>
					</div>
					<Pagination
						pageShowed={currentPage}
						totalPages={totalPages}
						handlePageChange={(page) => setCurrentPage(page)}
					/>
				</Container>
			</div>
		</>
	);
};

export default FavoriteDetail;
