import React, { useState, useEffect } from 'react';
// Services
import { sendUserSearchInformation } from '../../services/sendUserSearchInformation';
// Redux
import { useSelector, useDispatch } from 'react-redux';
// Styles
import './CardInterestingLinksHome.css';
import FavoriteButton from '../FavoriteButton';
import { FavoriteCategory } from '../../Views/FavoriteDetail/types';
import {
	getFavoriteByUrl,
	handleDeleteFavorite,
} from '../FavoriteButton/utils';
import { setActiveLoginModal } from '../../app/features/userSlice';

const CardInterestingLinksHome = ({
	title,
	description,
	url,
	image_url,
	is_default_image = true,
	type,
	resource_origin,
	favoriteType = 'favorite', // favorite | detail
	favoriteCategory = FavoriteCategory.INTERESTING_LINKS,
	onDeleteFavorite,
	showFavoriteButton = false,
	canExecuteLogger = true,
	hasLoginSettingsActive = true,
}) => {
	const { user_email } = useSelector((state) => state.user);
	const { institutionCode } = useSelector((state) => state.institution);
	const dispatch = useDispatch();
	const [favoriteStatus, setFavoriteStatus] = useState(false);
	const favorites = useSelector((state) => state.favorites.favorites);

	const handleOpenCard = () => {
		sendUserSearchInformation(
			title,
			resource_origin,
			url,
			type,
			user_email,
			institutionCode
		);
		window.open(url, '_blank');
	};

	useEffect(() => {
		const hasFavorite = getFavoriteByUrl(url, favorites);

		setFavoriteStatus(!!hasFavorite);
	}, [favorites, url]);

	const handleClickCard = () => {
		if (!hasLoginSettingsActive) {
			return handleOpenCard();
		}
		return canExecuteLogger
			? handleOpenCard()
			: dispatch(setActiveLoginModal(true));
	};
	if (url) {
		return (
			<div
				className='cardResource'
				onClick={handleClickCard}
				role='button'
				tabIndex='0'
			>
				<img
					className={
						is_default_image
							? type === 'resource'
								? 'cardResource__image resource'
								: 'cardResource__image'
							: type === 'resource'
							? 'cardResource__image default__image resource'
							: 'cardResource__image default__image'
					}
					src={image_url}
					alt=' resource'
				/>
				<div className='cardResourceContainer'>
					<p className='cardResource__title'>{title}</p>
					{description && <p className='cardResource__text'>{description}</p>}
					{showFavoriteButton && (
						<FavoriteButton
							bottomSpacing='1rem'
							topSpacing='auto'
							rightSpacing='1rem'
							type={favoriteType}
							bottomTooltip='0'
							category={favoriteCategory}
							favoriteData={{
								description,
								favoriteCategory,
								image_url,
								resource_origin,
								title,
								type,
								url,
							}}
							isFavorite={favoriteStatus}
							onDeleteFavorite={() =>
								handleDeleteFavorite(
									url,
									favorites,
									favoriteType,
									user_email,
									dispatch,
									onDeleteFavorite
								)
							}
							url={url}
						/>
					)}
				</div>
			</div>
		);
	}
};

export default CardInterestingLinksHome;
