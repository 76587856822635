import { fetchWithoutToken } from '../utils/fetch';

export const getInfoUserDigitalOcean = async (codeParam, institutionCode) => {
  const getCredentials = (institutionCode) => {
    switch (institutionCode) {
      case 'MIAMI_BROWARD':
        return {
          client_id: process.env.REACT_APP_CLIENTID_MIAMI_BROWARD,
          client_secret: process.env.REACT_APP_CLIENTSECRET_MIAMI_BROWARD,
          domain: 'https://broward.aquinasnetwork.com/',
        };
      default:
        return {
          client_id: process.env.REACT_APP_CLIENTID_MIAMI_BROWARD,
          client_secret: process.env.REACT_APP_CLIENTSECRET_MIAMI_BROWARD,
          domain: '',
        };
    }
  };

  try {
    const res = await fetchWithoutToken.post(
      `/digitalocean`,
      {
        client_id: getCredentials(institutionCode).client_id,
        client_secret: getCredentials(institutionCode).client_secret,
        code: codeParam,
        redirect: getCredentials(institutionCode).domain,
        base_url: `https://cloud.digitalocean.com/v1/oauth/token`,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    return res;
  } catch (error) {
    console.error('Your authorization token has expired or is incorrect.');
    return null;
  }
};
