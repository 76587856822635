import { fetchWithoutToken } from '../utils/fetch';

export const getInfoUserBlackboard = async (codeParam, institutionCode) => {
	const getBase64 = (client_id, client_secret) => {
		return btoa(`${client_id}:${client_secret}`).toString('base64');
	};
	const getCredentials = () => {
		switch (institutionCode) {
			case 'ILUNI':
				return {
					client_id: process.env.REACT_APP_CLIENTID_ILUNI,
					client_secret: process.env.REACT_APP_CLIENTSECRET_ILUNI,
					base_url: 'https://upanavirtual-test.blackboard.com', // LA URL DEL LOGIN DEL LMS DE BLACKBOARD
					redirect_uri: 'https://olib-dev.vercel.app', // DONDE VA A REDIRIGIR CUANDO SE HAGA LOGIN
					authorization: getBase64(
						process.env.REACT_APP_CLIENTID_ILUNI,
						process.env.REACT_APP_CLIENTSECRET_ILUNI // BASE 64 DEL CLIENT SECRET Y CLIENT ID
					),
				};
			case 'DEV': // Este es solo prueba
				return {
					client_id: process.env.REACT_APP_CLIENTID_ILUNI,
					client_secret: process.env.REACT_APP_CLIENTSECRET_ILUNI,
					base_url: 'https://upanavirtual-test.blackboard.com', // LA URL DEL LOGIN DEL LMS DE BLACKBOARD
					redirect_uri: 'https://olib-dev.vercel.app', // DONDE VA A REDIRIGIR CUANDO SE HAGA LOGIN
					authorization: getBase64(
						process.env.REACT_APP_CLIENTID_ILUNI,
						process.env.REACT_APP_CLIENTSECRET_ILUNI // BASE 64 DEL CLIENT SECRET Y CLIENT ID
					),
				};
			default:
				return {
					client_id: process.env.REACT_APP_CLIENTID_ILUNI,
					client_secret: process.env.REACT_APP_CLIENTSECRET_ILUNI,
					domain: 'iluni',
				};
		}
	};

	try {
		const institution = getCredentials(institutionCode);
		const res = await fetchWithoutToken.post(
			`/blackboard`,
			{
				base_url: institution.base_url,
				code: codeParam,
				redirect_uri: institution.redirect_uri,
				authorization: institution.authorization,
			},
			{
				headers: {
					Authorization: `Bearer ${localStorage.token}`,
					accept: 'application/json',
					'Content-Type': 'application/json',
				},
			}
		);

		return res;
	} catch (error) {
		console.error('Your authorization token has expired or is incorrect.');
		return null;
	}
};
