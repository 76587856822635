import { fetchWithoutToken } from '../utils/fetch';

// Este endpoint se usa para noticias bases (Sin busqueda de terminos)
export const getIASearch = async (prompt) => {
  try {
    const res = await fetchWithoutToken.post(`api/v2/ia_search?prompt=${prompt}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.token}`,
        'Content-Type': 'application/json',
      },
    });
    return res;
  } catch (error) {
    console.error(error);
    return error;
  }
};
