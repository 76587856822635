import { useSelector } from 'react-redux';

export const useAuth = () => {
	const { user_email } = useSelector((state) => state.user);
	const { requestLogin } = useSelector((state) => state.institution);

	const isAuth = () => {
		return user_email !== '';
	};

	const hasHasLoggingSettingsActive = () => {
		return requestLogin;
	};

	const isUserLoggedInAndHasLoggingSettingsActive = () => {
		return isAuth() && hasHasLoggingSettingsActive();
	};

	return {
		isAuth,
		hasHasLoggingSettingsActive,
		isUserLoggedInAndHasLoggingSettingsActive,
	};
};
