import React, { useState, useEffect } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Components
import CardInterestingLinks from '../../Components/CardInterestingLinksHome';
import Container from '../../Components/Container';
import Pagination from '../../Components/Pagination';
import FiltersInterestingLinks from '../../Components/FiltersInterestingLinks';
// Hooks
import { useScrollToTop } from '../../hooks/useScrollToTop';
// Styles
import './InterestingLinksPage.css';
import { FavoriteCategory } from '../FavoriteDetail/types';
import { useAuth } from '../../hooks/useAuth';

const InterestingLinks = () => {
	const { isAuth } = useAuth();
	const { handleScrollToTop } = useScrollToTop();
	// Variables
	const [pageShowed, setPageShowed] = useState(1);
	const [selectedInterestingLinks, setSelectedInterestingLinks] = useState([]);
	// Slices
	const { interestingLinks } = useSelector((state) => state.interestingLinks);

	useEffect(() => {
		setPageShowed(1);
	}, [selectedInterestingLinks]);

	// Funcion para modificar las noticias segun fuentes
	let selectedData = () => {
		if (selectedInterestingLinks.length > 0) {
			return interestingLinks?.filter((element) =>
				selectedInterestingLinks.includes(element.program)
			);
		} else {
			return interestingLinks;
		}
	};

	// Pagination
	const itemsPerPage = 12;
	const totalNews = selectedData().length;
	const indexOfLastItem = pageShowed * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = selectedData().slice(indexOfFirstItem, indexOfLastItem);
	const totalPages = Math.ceil(totalNews / itemsPerPage);

	// Funcion de cambio de paginacion
	const handlePageChange = (newPage) => {
		handleScrollToTop(0);
		if (newPage > pageShowed) {
			if (
				pageShowed < totalPages ||
				(Math.ceil(InterestingLinks?.length / itemsPerPage) > 1 &&
					totalPages === 0)
			) {
				setPageShowed(newPage);
			}
		} else {
			if (pageShowed > 1) {
				setPageShowed(newPage);
			}
		}
	};

	return (
		<main className='interesting-links'>
			<Container>
				<div className='interestingLinksFiltersContainer'>
					<FiltersInterestingLinks
						selected={selectedInterestingLinks}
						setSelected={setSelectedInterestingLinks}
					/>
				</div>

				<section className='interestingLinksResults'>
					{currentItems?.map((resource, i) => {
						return (
							<CardInterestingLinks
								key={i}
								title={resource.title}
								image_url={resource.image}
								url={resource.url}
								description={resource.program}
								type='resource'
								resource_origin={resource.program}
								favoriteCategory={FavoriteCategory.INTERESTING_LINKS}
								showFavoriteButton={isAuth() ? true : false}
							/>
						);
					})}
				</section>
				<Pagination
					pageShowed={pageShowed}
					totalPages={totalPages}
					handlePageChange={handlePageChange}
				/>
			</Container>
		</main>
	);
};

export default InterestingLinks;
