import React from 'react';
import './DataBaseSource.css';

const DataBaseSource = ({ dataBase }) => {
  if (dataBase.name !== 'DYNAMIC') {
    return (
      <img
        loading='lazy'
        className='dataBaseImg'
        src={`https://${dataBase.path}`}
        alt={dataBase.name}
      />
    );
  } else {
    return (
      <img
        loading='lazy'
        className='dataBaseImg'
        src={`https://${dataBase.path}`}
        alt={dataBase.name}
      />
    );
  }
};

export default DataBaseSource;
