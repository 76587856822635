import React from 'react';
// Redux
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setResourceType } from '../../app/features/metasearchSlice';
// Components
import Loading from '../Loading';
// utils
import { decimalToText } from '../../utils/decimalToText';
import { spanishInstitutions } from '../../utils/spanishInstitutions';
// Styles
import './NavigationBarModules.css';

const NavigationBarModules = ({
  active,
  type,
  resource,
  metasearcher,
  setActiveBar,
}) => {
  const dispatch = useDispatch();

  const { wordFetched } = useSelector((state) => state.metasearch);
  const { totalData, isLibraryLoadingMoreData } = useSelector(
    (state) => state.library
  );
  const { totalBooks, isBooksLoadingMoreData } = useSelector(
    (state) => state.books
  );
  const { total_multimedia, is_multimedia_loading } = useSelector(
    (state) => state.multimedia
  );
  const { total_news_query, is_news_loading } = useSelector(
    (state) => state.news
  );
  const { totalPatents, isLoadingMoreData } = useSelector(
    (state) => state.patents
  );
  const {
    elementaryData,
    medicalData,
    thesaurusData,
    isElementaryDataLoading,
    isMedicalDataLoading,
    isThesaurusDataLoading,
  } = useSelector((state) => state.dictionary);
  const { isMedicalInstitution, institutionCode } = useSelector(
    (state) => state.institution
  );

  const getTotalDictionary = (type) => {
    if (type) {
      return (typeof elementaryData[0] === 'string' ||
        elementaryData.length === 0) &&
        (typeof medicalData[0] === 'string' || medicalData.length === 0)
        ? 0
        : 1;
    } else {
      return typeof elementaryData[0] === 'string' ||
        elementaryData.length === 0
        ? 0
        : 1;
    }
  };

  const getTotalThesaurus = () => {
    return typeof thesaurusData[0] === 'string' || thesaurusData.length === 0
      ? 0
      : 1;
  };

  const getLoadingDictionary = () => {
    if (isMedicalInstitution) {
      return (
        isElementaryDataLoading &&
        isMedicalDataLoading &&
        isThesaurusDataLoading
      );
    } else {
      return isElementaryDataLoading && isThesaurusDataLoading;
    }
  };

  const getStatus = (resource) => {
    switch (resource) {
      case 'articles':
        return {
          loading: isLibraryLoadingMoreData,
          total: totalData,
        };
      case 'books':
        return {
          loading: isBooksLoadingMoreData,
          total: totalBooks,
        };
      case 'media':
        return {
          loading: is_multimedia_loading,
          total: total_multimedia,
        };
      case 'news':
        return {
          loading: is_news_loading,
          total: total_news_query,
        };
      case 'patents':
        return {
          loading: isLoadingMoreData,
          total: totalPatents,
        };
      case 'dictionary':
        return {
          loading: getLoadingDictionary(),
          total: getTotalDictionary(isMedicalInstitution),
        };
      case 'thesaurus':
        return {
          loading: getLoadingDictionary(),
          total: getTotalThesaurus(),
        };
      default:
        return null;
    }
  };

  const statusResource =
    type === 'metasearcher' ? getStatus(resource.route) : null;

  const handleClic = (element) => {
    dispatch(setResourceType(element));
    setActiveBar(false);
  };

  const getTitle = (resource) => {
    if (spanishInstitutions.includes(institutionCode)) {
      return resource.titulo !== 'Aquino IA'
        ? resource.titulo
        : 'Busca con Aquino IA';
    } else {
      return resource.title !== 'Aquino AI'
        ? resource.title
        : 'Search with Aquino AI';
    }
  };

  return (
    <Link
      to={resource.route}
      className={
        active
          ? 'activeSection'
          : resource.title === 'EBooks'
          ? 'EBookSection module'
          : resource.title === 'Aquino AI'
          ? 'EBookSection'
          : ''
      }
      onClick={() => handleClic(resource.route)}
    >
      <img
        className={
          resource.title === 'EBooks'
            ? 'newSource imageMainPanelSections'
            : resource.title === 'Aquino AI'
            ? 'newSource'
            : 'sectionIcon'
        }
        src={resource.icon}
        alt='module'
      />
      <>
        <p>{getTitle(resource)}</p>
        {/* {resource.title === 'Aquino AI' && (
          <img className='starsBar newSource' src={stars} alt='stars' />
        )} */}
        {metasearcher && wordFetched ? (
          statusResource?.loading && statusResource?.total === 0 ? (
            <Loading color={active ? 'green' : 'black'} />
          ) : (
            resource.title !== 'Aquino AI' &&
            resource.title !== 'EBooks' && (
              <p>{decimalToText(statusResource?.total)}</p>
            )
          )
        ) : null}
        {(resource.title === 'EBooks' || resource.title === 'Aquino AI') && (
          <p className='newSection'>New</p>
        )}
      </>
    </Link>
  );
};

export default NavigationBarModules;
