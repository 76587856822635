import React from 'react';
import './newFavoriteNotification.css';
const NewFavoriteNotification = () => {
	return (
		<div>
			<p className='text-small-notification'>Successfully saved. </p>
			<p className='green-aquinas-text text-small-notification'>
				Find it in your favorites folder
			</p>
		</div>
	);
};
export default NewFavoriteNotification;
