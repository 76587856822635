import HeartLike from '../../Images/Login/Modal/heartLike.svg';
import HeartLiked from '../../Images/Login/Modal/heartLiked.svg';
import './favoriteButton.css';
import { useFavoriteButton } from './useFavoriteButton';
import DotsIcon from '../../Images/Login/Modal/dots.svg';
import linkIcon from '../../Images/Login/Modal/url-small-green.svg';
import trashCanSmall from '../../Images/Login/Modal/trashSmall-green.svg';
import { FavoriteCategory } from '../../Views/FavoriteDetail/types';
import { preventBehavior } from '../../utils/preventBehavior';

const FavoriteButton = ({
	favoriteData,
	isFavorite,
	isAbsolute = true,
	topSpacing = '1.5rem',
	rightSpacing = '1.5rem',
	bottomSpacing = 'auto',
	bottomTooltip = 'auto',
	rightTooltip = '0.3rem',
	category = FavoriteCategory.ARTICLES, // These are the categories, can be found in FavoriteCategories in types
	type = 'favorite', // favorite (When outside the favorite detail), detail
	onDeleteFavorite,
	url,
}) => {
	const {
		handleToggleTooltip,
		showTooltip,
		buttonRef,
		tooltipRef,
		handleCreateNewFavorite,
		copyToClipboard,
	} = useFavoriteButton();

	const styles = {
		top: isAbsolute ? topSpacing : '0',
		right: isAbsolute ? rightSpacing : '0',
		bottom: isAbsolute ? bottomSpacing : '0',
	};

	return (
		<div
			className={`fav-component ${isAbsolute ? '' : 'no-absolute'}`}
			style={styles}
		>
			{type === 'favorite' && (
				<button
					className='fav-button add-zoom-x3'
					onClick={(event) => {
						if (!isFavorite) {
							handleCreateNewFavorite(category, favoriteData, event);
						}
						if (isFavorite) {
							preventBehavior(event);
							onDeleteFavorite();
						}
					}}
				>
					<img src={isFavorite ? HeartLiked : HeartLike} alt='liked icon' />
				</button>
			)}

			{type === 'detail' && (
				<div
					className='favorite-tooltip-detail'
					style={{ right: rightTooltip, bottom: bottomTooltip }}
				>
					<button
						ref={buttonRef}
						className='tooltip-fav-action-btn'
						onClick={(e) => handleToggleTooltip(e)}
					>
						<img src={DotsIcon} alt='dots tooltip' />
					</button>

					{showTooltip && (
						<div className='favorite-tooltip__btns' ref={tooltipRef}>
							<button
								className='favorite-tooltip__btns_btn'
								onClick={(e) => {
									if (onDeleteFavorite) {
										preventBehavior(e);
										onDeleteFavorite();
									}
								}}
							>
								<img src={trashCanSmall} alt='trashcan' />
								Delete
							</button>
							<button
								className='favorite-tooltip__btns_btn'
								onClick={(e) => {
									if (copyToClipboard) {
										preventBehavior(e);
										copyToClipboard(url);
									}
								}}
							>
								<img src={linkIcon} className='copy-icon' alt='copy url' />
								Copy link
							</button>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default FavoriteButton;
