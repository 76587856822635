import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Components
import Container from '../../Components/Container';
import Footer from '../../Components/Footer';
// Services
import { sendCreationAccount } from '../../services/sendCreationAccount';
// Icons
import { IoChevronBackOutline } from 'react-icons/io5';
import { HiOutlineUser } from 'react-icons/hi';
import { RiLockPasswordLine } from 'react-icons/ri';
// Images
import aquinoLogin from '../../Images/Login/LoginPage/aquinoLogin.svg';
// Styles
import './SignUp.css';

const SignUp = () => {
  const navigate = useNavigate();
  const { institutionCode } = useSelector((state) => state.institution);

  // Variables
  const [fullname, setFullname] = useState('');
  const [emailForm, setEmailForm] = useState('');

  const [error, setError] = useState(false);
  const [errorUser, setErrorUser] = useState(false);
  const [success, setSuccess] = useState(false);

  const sendDataUserForm = (fullname, email) => {
    sendCreationAccount(fullname, email, institutionCode)
      .then((response) => {
        if (response.status === 200) {
          showSuccessMessage();
        } else {
          setErrorUser(true);
          setTimeout(() => {
            setErrorUser(false);
            navigate('/');
          }, '8000');
        }
      })
      .catch((error) => {
        showErrorMessage();
      });
  };

  const showSuccessMessage = () => {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
      navigate('/');
    }, '6000');
  };

  const showErrorMessage = () => {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, '6000');
  };

  return (
    <main className='loginPage'>
      <Container className='loginPageContainer'>
        <button className='backLoginButton' onClick={() => navigate('/')}>
          <IoChevronBackOutline />
          Return
        </button>
        <div className='loginContainer'>
          <img src={aquinoLogin} alt='Aquino' />
          <p className='info'>
            Welcome to <b>Aquinas Online Library!</b>
            <br /> <b>Create your account</b> and access our{' '}
            <b>exclusive resources.</b>
          </p>
          <form
            className='formLogin'
            onSubmit={(e) => {
              e.preventDefault();
              sendDataUserForm(fullname, emailForm);
            }}
          >
            <div className='formLogin__inputs'>
              <div>
                <div className='input-container'>
                  <label>Full name</label>
                  <input
                    onChange={(e) => setFullname(e.target.value)}
                    type='text'
                    name='full name'
                    placeholder='full name'
                    required
                  />
                  <HiOutlineUser />
                </div>
              </div>
              <div>
                <div className='input-container'>
                  <label>Email</label>
                  <input
                    onChange={(e) => setEmailForm(e.target.value)}
                    type='email'
                    name='name'
                    placeholder='email'
                    required
                  />
                  <RiLockPasswordLine />
                </div>
              </div>
            </div>
            <button className='btnLogin' type='submit'>
              Sign up
            </button>
          </form>
          {error && (
            <p className='errorMessage'>Error on request. Please try again.</p>
          )}
          {success && (
            <p className='sucessMessage'>
              Your request was successfully processed. Your user credentials
              will be sent to your email.
            </p>
          )}
          {errorUser && <p className='errorMessage'>User already exists</p>}
        </div>
        <section className='infoSectionContrainer'>
          <Link to={'/login'} className='aux-text'>
            Already have a user account?
          </Link>
          <Link to={'/recover'} className='aux-text'>
            Forgot your password?
          </Link>
        </section>
      </Container>
      <Footer allContent={false} />
    </main>
  );
};

export default SignUp;
