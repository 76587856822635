import React from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Icons
import { IoMdSearch } from 'react-icons/io';
// Slices
import {
  setPage,
  setVideos,
  setQueryLibraryVideos,
  setQueryLibraryVideosSearched,
} from '../../app/features/libraryVideosSlice';
// Services
import { getLibraryVideos } from '../../services/getLibraryVideos';
// Styles
import './LibraryVideoSearchBar.css';

const LibraryVideoSearchBar = () => {
  const dispatch = useDispatch();
  const { queryLibraryVideos } = useSelector((state) => state.libraryVideos);

  const searchData = (query) => {
    dispatch(setVideos(getLibraryVideos(query)));
    dispatch(setQueryLibraryVideosSearched(query));
    dispatch(setPage(1));
  };

  return (
    <form
      className='metasearchBarContainer'
      onSubmit={(e) => {
        e.preventDefault();
        searchData(queryLibraryVideos);
      }}
    >
      <input
        placeholder='Do a video search...'
        className='metasearchBar'
        type='text'
        value={queryLibraryVideos}
        onChange={(e) => dispatch(setQueryLibraryVideos(e.target.value))}
      ></input>

      <button className='metasearchButton' type='submit'>
        <p>Search</p>
        <IoMdSearch />
      </button>
    </form>
  );
};

export default LibraryVideoSearchBar;
