import React from 'react';
import { useNavigate } from 'react-router-dom';
// Hooks
import { useBooks } from '../../hooks/useBooks';
import { useLibrary } from '../../hooks/useLibrary';
import { usePatents } from '../../hooks/usePatents';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Utils
import { currentYear } from '../../utils/getYearsFilters';
// Slices
import {
	setTotalNewsQuery,
	setIsNewsLoading,
	setIsNewsDataFetched,
	setResetNewsQuery,
	setNews,
	setQueryNewsView,
} from '../../app/features/newsSlice';
import {
	setWord,
	setWordFetched,
	setWordFetchedAdvanced,
	setLoadingMetasearch,
} from '../../app/features/metasearchSlice';
import {
	setMultimedia,
	setIsMultimediaLoading,
	setTotalMultimedia,
	resetMultimedia,
	setIsMediaDataFetched,
} from '../../app/features/multimediaSlice';
import {
	setIsDictionaryDataFetched,
	setElementaryData,
	setIsElementaryDataLoading,
	setMedicalData,
	setIsMedicalDataLoading,
	setThesaurusData,
	setIsThesaurusDataLoading,
	resetDictionaty,
} from '../../app/features/dictionarySlice';
// Services
import { getMultimedia } from '../../services/getMultimedia';
import { getNewsFromQuery } from '../../services/getNewsFromQuery';
import { sendUserInformation } from '../../services/sendUserInformation';
import {
	getElementaryDictionary,
	getMedicalDictionary,
	getThesaurusDictionary,
} from '../../services/getDictionary';
// Styles
import './Topic.css';
import { setActiveLoginModal } from '../../app/features/userSlice';

const Topic = ({ name, path, canExecuteLogger, hasLoginSettingsActive }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// Hooks
	const { getPatentsDataSocket } = usePatents();
	const { getBooksDataSocket } = useBooks();
	const { getLibraryDataSocket } = useLibrary();

	// Slices
	const { user_email } = useSelector((state) => state.user);
	const { searchType, allLibrarySources } = useSelector(
		(state) => state.library
	);
	const { allBooksSources } = useSelector((state) => state.books);
	const { allPatentsSources } = useSelector((state) => state.patents);
	const { isMedicalInstitution, institutionCode } = useSelector(
		(state) => state.institution
	);

	const getData = (name) => {
		dispatch(setWord(name.toLowerCase()));
		// Envio de datos de busqueda y de usuario
		sendUserInformation(
			'library',
			name.toLowerCase(),
			user_email,
			institutionCode
		);
		// Activa loading del Metabuscador
		dispatch(setLoadingMetasearch(true));
		searchType !== 'simple'
			? dispatch(setWordFetchedAdvanced(name.toLowerCase()))
			: dispatch(setWordFetched(name.toLowerCase()));

		// Library
		getLibraryDataSocket(
			name.toLowerCase(),
			1,
			false,
			1990,
			currentYear,
			allLibrarySources,
			[],
			searchType,
			false
		);

		// Multimedia
		dispatch(resetMultimedia());
		dispatch(setIsMultimediaLoading(true));
		getMultimedia(name.toLowerCase(), 1)
			.then((response) => {
				if (response.status === 200) {
					dispatch(setIsMediaDataFetched(true));
					dispatch(setMultimedia(response?.data?.data));
					dispatch(setTotalMultimedia(response?.data?.count));
				}
				dispatch(setIsMultimediaLoading(false));
			})
			.catch((error) => {
				console.error('Request error:', error);
				dispatch(setMultimedia([]));
				dispatch(setTotalMultimedia(0));
				dispatch(setIsMultimediaLoading(false));
			});

		// News
		dispatch(setResetNewsQuery());
		dispatch(setQueryNewsView(true));
		dispatch(setIsNewsLoading(true));
		getNewsFromQuery(name.toLowerCase(), 1)
			.then((response) => {
				if (response.status === 200) {
					dispatch(setIsNewsDataFetched(true));
					dispatch(setNews(response?.data?.data));
					dispatch(setTotalNewsQuery(response?.data?.count));
				}
				dispatch(setIsNewsLoading(false));
			})
			.catch((error) => {
				console.error(error);
				dispatch(setNews([]));
				dispatch(setTotalNewsQuery(0));
				dispatch(setIsNewsLoading(false));
			});

		// Dictionary
		dispatch(resetDictionaty());
		if (isMedicalInstitution) {
			dispatch(setIsMedicalDataLoading(true));
			getMedicalDictionary(name.toLowerCase())
				.then((response) => {
					if (typeof response.data[0] !== 'string') {
						let dataMedical = [];
						response.data?.map((element) => {
							dataMedical.push({
								meta: element.meta,
								shortdef: element.shortdef,
							});
							return null;
						});
						dispatch(setMedicalData(dataMedical));
					} else {
						dispatch(setMedicalData(response?.data));
					}
					dispatch(setIsMedicalDataLoading(false));
					dispatch(setIsDictionaryDataFetched(true));
				})
				.catch((error) => {
					dispatch(setIsMedicalDataLoading(false));
					console.error('Request error:', error);
				});
		}
		dispatch(setIsThesaurusDataLoading(true));
		getThesaurusDictionary(name.toLowerCase())
			.then((response) => {
				if (typeof response.data[0] !== 'string') {
					let dataThesaurus = [];
					response.data?.map((element) => {
						dataThesaurus.push({
							meta: element.meta,
							shortdef: element.shortdef,
						});
						return null;
					});
					dispatch(setThesaurusData(dataThesaurus));
				} else {
					dispatch(setThesaurusData(response?.data));
				}
				dispatch(setIsThesaurusDataLoading(false));
				dispatch(setIsDictionaryDataFetched(true));
			})
			.catch((error) => {
				console.error('Request error:', error);
				dispatch(setIsThesaurusDataLoading(false));
			});
		dispatch(setIsElementaryDataLoading(true));
		getElementaryDictionary(name.toLowerCase())
			.then((response) => {
				if (typeof response.data[0] !== 'string') {
					let dataElementary = [];
					response.data?.map((element) => {
						dataElementary.push({
							meta: element.meta,
							shortdef: element.shortdef,
							history: element.history,
						});
						return null;
					});
					dispatch(setElementaryData(dataElementary));
				} else {
					dispatch(setElementaryData(response?.data));
				}
				dispatch(setIsElementaryDataLoading(false));
				dispatch(setIsDictionaryDataFetched(true));
			})
			.catch((error) => {
				console.error('Request error:', error);
				dispatch(setIsElementaryDataLoading(false));
			});

		// Patents
		getPatentsDataSocket(
			name.toLowerCase(),
			1,
			1990,
			currentYear,
			allPatentsSources
		);

		// Books
		getBooksDataSocket(
			name.toLowerCase(),
			1,
			1990,
			currentYear,
			allBooksSources,
			[],
			'',
			'',
			'en'
		);

		// Redireccion hacia Articles
		navigate(`/articles`);
	};

	const handleClick = () => {
		if (!hasLoginSettingsActive) {
			getData(name);
			return;
		}

		return canExecuteLogger && hasLoginSettingsActive
			? getData(name)
			: dispatch(setActiveLoginModal(true));
	};

	return (
		<div role='button' onClick={handleClick} className='topicCard'>
			<div className='topicCardImage'>
				<img loading='lazy' src={`${path}`} alt={name} />
			</div>

			<div className='topicCardText'>
				<h3>
					{name === 'Aha' ||
					name === 'Emt' ||
					name === 'Lpn' ||
					name === 'Hha' ||
					name.toLowerCase() === 'hvacr' ||
					name === 'Bht'
						? name.replace('-', ' ').toUpperCase()
						: name.replace('-', ' ')}
				</h3>
				{/* <p>
          Ballpoint Pen: Uses a small rotating ball to dispense oil-based ink.
        </p> */}
			</div>
		</div>
	);
};

export default Topic;
