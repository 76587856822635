import React from 'react';
import './LinkCopiedNotification.css';
const LinkCopiedNotification = () => {
	return (
		<div>
			<p className='text-small-notification blue-aquinas-text '>
				The link has been copied successfully.
			</p>
		</div>
	);
};
export default LinkCopiedNotification;
