import React, { useEffect, useState } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Components
import Modal from 'react-modal';
// Services
import { sendUserSearchInformation } from '../../services/sendUserSearchInformation';
// Images
import PlayButtonImage from '../../Images/play-button.svg';
import PlaceholderImage from '../../Images/placeholder-image.png';
// Styles
import './CardVideo.css';
import FavoriteButton from '../FavoriteButton';
import { FavoriteCategory } from '../../Views/FavoriteDetail/types';
import {
	getFavoriteByUrl,
	handleDeleteFavorite,
} from '../FavoriteButton/utils';

const CardVideo = ({
	image,
	time,
	title,
	description,
	url,
	language,
	type = 'youtube',
	favoriteType = 'favorite', // favorite | detail
	favoriteCategory = FavoriteCategory.MEDIA,
	onDeleteFavorite,
	showFavoriteButton = true,
}) => {
	const dispatch = useDispatch();
	const [favoriteStatus, setFavoriteStatus] = useState(false);
	const favorites = useSelector((state) => state.favorites.favorites);
	const { user_email } = useSelector((state) => state.user);
	const { institutionCode } = useSelector((state) => state.institution);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [selectedVideoUrl, setSelectedVideoUrl] = useState('');

	// Type youtube | vimeo | external | new_tab
	const getURL = (id, type) => {
		switch (type) {
			case 'youtube':
				return `https://www.youtube.com/embed/${id}`;
			case 'vimeo':
				return `https://player.vimeo.com/video/${id}`;
			case 'external':
				return url;

			default:
				return `https://www.youtube.com/embed/${id}`;
		}
	};

	const handleClicCard = (id, type) => {
		setSelectedVideoUrl(`${getURL(id, type)}`);
		setModalIsOpen(true);
		sendUserSearchInformation(
			title,
			'Google scholar',
			url,
			'Media',
			user_email,
			institutionCode
		);
	};

	// Manejador de modal (close)
	const closeModal = () => {
		setSelectedVideoUrl('');
		setModalIsOpen(false);
	};

	useEffect(() => {
		const hasFavorite = getFavoriteByUrl(url, favorites);

		setFavoriteStatus(!!hasFavorite);
	}, [favorites, url]);
	return (
		<>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Video Modal'
				style={{
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						transform: 'translate(-50%, -50%)',
						maxWidth: '80%',
						maxHeight: '80%',
					},
				}}
			>
				{type !== 'external' && (
					<iframe
						className='videoModal'
						title='title video'
						src={selectedVideoUrl}
						controls
						allowFullScreen
					></iframe>
				)}

				{type === 'external' && (
					<iframe
						src={selectedVideoUrl}
						width='750'
						height='483'
						frameborder='0'
						allowfullscreen='allowfullscreen'
						title='Aquinas Online Library - ENG'
					></iframe>
				)}
			</Modal>
			<div
				className='card-video'
				// onClick={() => handleClicCard(url, type)}
				onClick={() =>
					type !== 'new_tab'
						? handleClicCard(url, type)
						: window.open(url, '_blank')
				}
				role='button'
				tabIndex='0'
			>
				<div className='thumbnail_image'>
					<div className='thumbnail_image__play-button'>
						<img src={PlayButtonImage} alt='play' className='button-svg' />
					</div>
					<div className='thumbnailContainer'>
						<img
							src={image ? image : PlaceholderImage}
							alt='thumbnail'
							className='thumbnail'
						/>
					</div>

					{time && <div className='thumbnail_time'>{time}</div>}
				</div>

				<div className='resource_content'>
					<div className='resource_content__top-content'>
						{title && <div className='resource_title'>{title}</div>}
						{showFavoriteButton && (
							<FavoriteButton
								isAbsolute={false}
								type={favoriteType}
								category={favoriteCategory}
								favoriteData={{
									description,
									favoriteCategory,
									language,
									time,
									title,
									type,
									url,
									image,
								}}
								isFavorite={favoriteStatus}
								onDeleteFavorite={() =>
									handleDeleteFavorite(
										url,
										favorites,
										favoriteType,
										user_email,
										dispatch,
										onDeleteFavorite
									)
								}
								// onDeleteFavorite={onDeleteFavorite}
								url={type === 'new_tab' ? url : getURL(url, type)}
							/>
						)}
					</div>

					{description && (
						<div className='resource_description'>{description}</div>
					)}
				</div>
			</div>
		</>
	);
};

export default CardVideo;
