import React from 'react';
// Skeleton Loading
import { LoadingSkeleton } from '../../Components/LoadingSkeleton';
import { SkeletonTypes } from '../../Components/LoadingSkeleton/types';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
	setPageShowed,
	setIsBooksLoadingMoreData,
} from '../../app/features/booksSlice';
// hooks
import { useScrollToTop } from '../../hooks/useScrollToTop';
import { useBooks } from '../../hooks/useBooks';
// Components
import Card from '../../Components/Card';
import Container from '../../Components/Container';
import Pagination from '../../Components/Pagination';
import FiltersBooks from '../../Components/FiltersBooks';
import SummarySearch from '../../Components/SummarySearch';
// Styles
import './Books.css';
import { FavoriteCategory } from '../FavoriteDetail/types';
import { useAuth } from '../../hooks/useAuth';

const BooksPage = () => {
	const { handleScrollToTop } = useScrollToTop();
	const { isAuth } = useAuth();

	const dispatch = useDispatch();
	const { getBooksDataSocket } = useBooks();

	const {
		books,
		totalBooks,
		filters,
		pageShowed,
		isBooksLoading,
		queryFetched,
	} = useSelector((state) => state.books);

	// Pagination
	const itemsPerPage = 12;

	// Resultados para pagina actual
	const indexOfLastItem = pageShowed * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = books.slice(indexOfFirstItem, indexOfLastItem);
	const totalPages = Math.ceil(totalBooks / itemsPerPage);

	// Resultados para siguiente pagina
	const indexOfLastItemNext = (pageShowed + 1) * itemsPerPage;
	const indexOfFirstItemNext = indexOfLastItemNext - itemsPerPage;
	const nextItems = books.slice(indexOfFirstItemNext, indexOfLastItemNext);

	const handlePageChange = (newPage) => {
		handleScrollToTop(400);
		if (newPage > pageShowed) {
			if (
				pageShowed < totalPages ||
				(Math.ceil(books?.length / itemsPerPage) > 1 && totalPages === 0)
			) {
				dispatch(setPageShowed(newPage));
				if (nextItems.length < itemsPerPage) {
					dispatch(setIsBooksLoadingMoreData(true));
					getBooksDataSocket(
						filters.queryFetched,
						filters.pageFetched + 1,
						filters.startYearFetched,
						filters.endYearFetched,
						filters.sourcesFetched,
						filters.keywordsFetched,
						filters.authorsFetched,
						filters.topicFetched,
						filters.languageFetched
					);
				}
			}
		} else {
			if (pageShowed > 1) {
				dispatch(setPageShowed(newPage));
			}
		}
	};

	return (
		<>
			{isBooksLoading && (
				<main className='books-content'>
					<Container>
						<section className='results'>
							{Array.from({ length: 10 }).map((card, i) => (
								<LoadingSkeleton key={i} type={SkeletonTypes.LIBRARY_CARD} />
							))}
						</section>
					</Container>
				</main>
			)}

			{books?.length > 0 ? (
				<main className='books-content'>
					<Container>
						<div className='metasearchLibraryContainer__info'>
							<SummarySearch total={totalBooks} />
							<FiltersBooks />
						</div>
						<section>
							<div className='results'>
								{currentItems?.map((libraryItem, i) => (
									<Card
										key={i}
										title={libraryItem?.title}
										authors={libraryItem?.authors}
										abstract={libraryItem?.abstract}
										publication_date={libraryItem?.publication_date}
										url={libraryItem?.url}
										type={libraryItem?.type}
										resource_origin={
											libraryItem?.source_code
												? libraryItem?.source_code.replace('_', ' ')
												: 'No data source'
										}
										peer_reviewed={libraryItem?.peer_reviewed}
										favoriteCategory={FavoriteCategory.BOOKS}
										showFavoriteButton={isAuth() ? true : false}
									/>
								))}
							</div>

							<Pagination
								pageShowed={pageShowed}
								totalPages={totalPages}
								handlePageChange={handlePageChange}
							/>
						</section>
					</Container>
				</main>
			) : isBooksLoading === false &&
			  totalBooks === 0 &&
			  queryFetched.length === 0 ? (
				<main className='noDataContainer'>
					<p>Please enter a search term</p>
				</main>
			) : (
				<main className='noDataContainer'>
					<p>No data found</p>
				</main>
			)}
		</>
	);
};

export default BooksPage;
