import { useEffect, useState } from 'react';
import { FavoriteCategory } from '../FavoriteDetail/types';
import {
	deleteFavoriteCategory,
	getFavoritesByCategory,
} from '../../services/favorites';
import { setIsLibraryLoading } from '../../app/features/librarySlice';

// Images
import ArticlseBg from '../../Images/HomePage/Sections/artSection.webp';
import PatentsBg from '../../Images/HomePage/Sections/patentsSection.webp';
import NewsBg from '../../Images/HomePage/Sections/newsSection.webp';
import MediaBg from '../../Images/HomePage/Sections/media.png';
import BooksBg from '../../Images/HomePage/Sections/booksSection.webp';
import InterestingLinksBg from '../../Images/HomePage/Sections/interestingLinksSection.webp';
import SoftSkillsBg from '../../Images/HomePage/Sections/softSkillsSection.webp';
import TutorialsBg from '../../Images/HomePage/Sections/thesaurusSection.webp';

import { toast } from 'react-toastify';
import { DeleteFavoriteNotification } from '../../Components/Notification';
import { useDispatch, useSelector } from 'react-redux';
import { handleGetFavorites } from '../../Components/FavoriteButton/utils';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

export const useMyFavoritesPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { isAuth } = useAuth();
	const { user_email } = useSelector((state) => state.user);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [favorites, setFavorites] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const imageCollection = [
		{
			image: ArticlseBg,
			type: FavoriteCategory.ARTICLES,
		},

		{
			image: PatentsBg,
			type: FavoriteCategory.PATENTS,
		},
		{
			image: NewsBg,
			type: FavoriteCategory.NEWS,
		},
		{
			image: MediaBg,
			type: FavoriteCategory.MEDIA,
		},
		{
			image: BooksBg,
			type: FavoriteCategory.BOOKS,
		},
		{
			image: InterestingLinksBg,
			type: FavoriteCategory.INTERESTING_LINKS,
		},
		{
			image: SoftSkillsBg,
			type: FavoriteCategory.SOFT_SKILLS,
		},
		{
			image: TutorialsBg,
			type: FavoriteCategory.TUTORIALS,
		},
	];

	const handleGetFavoritesCategories = async (email) => {
		setIsLibraryLoading(true);
		try {
			const favorites = await getFavoritesByCategory(email);
			// Sort the favorites based on FavoriteCategory order
			const orderedFavorites = favorites.sort((a, b) => {
				const order = Object.values(FavoriteCategory);
				return order.indexOf(a.type) - order.indexOf(b.type);
			});
			setFavorites(orderedFavorites);

			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error(error);
			toast.error('Error, please try again');
		}
	};

	const handleDeleteCategory = async (email, category) => {
		try {
			await deleteFavoriteCategory(email, category);
			await handleGetFavorites(user_email, dispatch);
			await handleGetFavoritesCategories(email);

			handleCloseDeleteModal();
			toast(<DeleteFavoriteNotification variant='deleteItem' />, {
				type: 'success',
			});
		} catch (error) {
			console.error(error);
			toast.error('Error, please try again');
		}
	};

	const handleShowDeleteModal = (favoriteCategory) => {
		if (!favoriteCategory) return console.error('favoriteCategory is required');
		setSelectedCategory(favoriteCategory);
		setShowDeleteModal(true);
	};

	const handleCloseDeleteModal = () => {
		setShowDeleteModal(false);
		setSelectedCategory(null);
	};

	const getImage = (type) => {
		const image = imageCollection.find((image) => image.type === type)?.image;
		return image ? image : imageCollection[0].image;
	};

	useEffect(() => {
		// This section can only be accessed if looged in
		if (!isAuth()) {
			navigate('/');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (user_email) {
			handleGetFavoritesCategories(user_email);
		}
	}, [user_email]);

	return {
		handleShowDeleteModal,
		handleCloseDeleteModal,
		showDeleteModal,
		favorites,
		getImage,
		loading,
		handleDeleteCategory,
		selectedCategory,
		user_email,
	};
};
