import React from 'react';
import { Link } from 'react-router-dom';
// Styles
import './Navbar.css';

const Navbar = ({ type }) => {
  return (
    <nav>
      <ul className='navbar'>
        <li>
          <Link
            className={type === 'white' ? 'whiteText' : 'blackText'}
            to='/news'
          >
            Global News
          </Link>
        </li>
        <li>
          <Link
            className={type === 'white' ? 'whiteText' : 'blackText'}
            to='/help'
          >
            FAQs
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
