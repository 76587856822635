import { fetchWithoutToken } from '../utils/fetch';

/**
 * Creates a new favorite for a user.
 *
 * @param {Object} user - The user object containing user details.
 * @param {string} type - The type of the resource (e.g., "articles").
 * @param {Object} resource - The resource object containing details of the favorite item.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const createFavorite = async (user, type, resource) => {
	const response = await fetchWithoutToken.post('users/new_favorite', {
		user,
		type,
		resource,
	});

	return response.data;
};

/**
 * Fetches favorite resources by category for a given user.
 *
 * @param {string} email - The email of the user.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const getFavoritesByCategory = async (email) => {
	const response = await fetchWithoutToken.get(
		`users/favorites/category?user_email=${encodeURIComponent(email)}`
	);

	return response.data;
};

/**
 * Deletes a favorite category for a user.
 *
 * @param {string} email - The email of the user.
 * @param {string} category - The category to delete.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const deleteFavoriteCategory = async (email, category) => {
	const response = await fetchWithoutToken.delete(
		`users/favorites/category?user_email=${encodeURIComponent(
			email
		)}&category=${encodeURIComponent(category)}`
	);

	return response.data;
};

/**
 * Fetches favorite items of a specific category for a given user.
 *
 * @param {string} email - The email of the user.
 * @param {string} category - The category of favorites to retrieve.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const getFavoritesByCategoryAndType = async (
	email,
	category,
	itemsPerPage = 6,
	page = 1
) => {
	// const response = await fetchWithoutToken.get(
	// 	`users/favorites?user_email=${encodeURIComponent(
	// 		email
	// 	)}&category=${encodeURIComponent(category)}`
	// );

	// return response.data;
	const response = await fetchWithoutToken.get(
		`users/favorites?user_email=${encodeURIComponent(
			email
		)}&category=${encodeURIComponent(
			category
		)}&items_per_page=${itemsPerPage}&page=${page}`,
		{
			headers: {
				Accept: 'application/json',
			},
		}
	);

	return response.data;
};

/**
 * Deletes a specific favorite by its ID.
 *
 * @param {string} favoriteId - The ID of the favorite to delete.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const deleteFavoriteById = async (favoriteId) => {
	const response = await fetchWithoutToken.delete(
		`users/favorites?favorite_id=${encodeURIComponent(favoriteId)}`
	);

	return response.data;
};

/**
 * Fetches favorite links for a given user.
 *
 * @param {string} userEmail - The email of the user.
 * @returns {Promise<Object>} - The response data from the API.
 */
export const getFavoriteLinks = async (userEmail) => {
	const response = await fetchWithoutToken.get(
		`users/favorites/links?user_email=${encodeURIComponent(userEmail)}`
	);

	return response.data;
};
