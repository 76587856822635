import axios from 'axios';

export const fetchWithoutToken = async (config) => {
	try {
		// Puedes realizar cualquier manipulación adicional de la configuración de axios aquí si es necesario
		const response = await axios(config);

		// Puedes realizar cualquier manipulación adicional de la respuesta aquí si es necesario
		return response;
	} catch (error) {
		// Puedes realizar cualquier manipulación adicional del error aquí si es necesario
		throw error;
	}
};

// Añadimos métodos adicionales como axios.get, axios.post, etc.
fetchWithoutToken.get = async function (url, config) {
	return fetchWithoutToken({ ...config, method: 'get', url });
};

fetchWithoutToken.post = async function (url, data, config) {
	return fetchWithoutToken({ ...config, method: 'post', url, data });
};

fetchWithoutToken.delete = async function (url, config) {
	return fetchWithoutToken({ ...config, method: 'delete', url });
};
