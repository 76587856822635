import ArtPage from '../Art';
import HomePage from '../Home';
import NewsPage from '../News';
import HelpPage from '../Help';
import JobsPage from '../Jobs';
import LoginPage from '../Login';
import SearchPage from '../Search';
import AIPage from '../AI';
import LibraryPage from '../Library';
import BooksPage from '../Books';
import EBooksPage from '../EBooks';
import PatentsPage from '../Patents';
import TutoringPage from '../Tutoring';
import TutorialsPage from '../Tutorial';
import InterestingLinksPage from '../InterestingLinks';
import AmericanLife from '../AmericanLife';
import VideoLibrary from '../VideoLibrary';
import DictionaryPage from '../Dictionary';
import ThesaurusPage from '../Thesaurus';
import MediaResourcesPage from '../MediaResources';
import NotFound from '../NotFound';
import SignUpPage from '../SignUp';
import RecoverPage from '../Recover';
import MyFavoritesPage from '../MyFavorites';
import FavoriteDetail from '../FavoriteDetail';

const appPages = [
	{
		path: '',
		title: 'Home',
		element: <HomePage />,
	},
	{
		path: '/login',
		title: 'Login',
		element: <LoginPage />,
	},
	{
		path: '/signup',
		title: 'Sign Up',
		element: <SignUpPage />,
	},
	{
		path: '/recover',
		title: 'Recover',
		element: <RecoverPage />,
	},
	{
		path: '/ai',
		title: 'Aquino AI',
		element: <AIPage />,
	},
	{
		path: '/search',
		title: 'Search',
		element: <SearchPage />,
	},
	{
		path: '/articles',
		title: 'Articles',
		element: <LibraryPage />,
	},
	{
		path: '/books',
		title: 'Books',
		element: <BooksPage />,
	},
	{
		path: '/ebooks',
		title: 'EBooks',
		element: <EBooksPage />,
	},
	{
		path: '/dictionary',
		title: 'Dictionary',
		element: <DictionaryPage />,
	},
	{
		path: '/thesaurus',
		title: 'Thesaurus',
		element: <ThesaurusPage />,
	},
	{
		path: '/interesting-links',
		title: 'Interesting  Links',
		element: <InterestingLinksPage />,
	},
	{
		path: '/tutorials',
		title: 'Tutorials',
		element: <TutorialsPage />,
	},
	{
		path: '/tutoring',
		title: 'Tutoring',
		element: <TutoringPage />,
	},
	{
		path: '/news',
		title: 'News',
		element: <NewsPage />,
	},
	{
		path: '/help',
		title: 'Help',
		element: <HelpPage />,
	},
	{
		path: '/media',
		title: 'Media',
		element: <MediaResourcesPage />,
	},
	{
		path: '/patents',
		title: 'Patents',
		element: <PatentsPage />,
	},
	{
		path: '/jobs',
		title: 'Jobs',
		element: <JobsPage />,
	},
	{
		path: '/american-life',
		title: 'Amelican life',
		element: <AmericanLife />,
	},
	{
		path: '/video-library',
		title: 'Video Library',
		element: <VideoLibrary />,
	},
	{
		path: '/art',
		title: 'Art',
		element: <ArtPage />,
	},
	{
		path: '/my-favorites',
		title: 'My favorites',
		element: <MyFavoritesPage />,
	},
	{
		path: '/favorites',
		title: 'Favorite Category',
		element: <FavoriteDetail />,
	},
	{
		path: '*',
		title: '*',
		element: <NotFound />,
	},
];

export default appPages;
