import React, { useState } from 'react';
// Redux
import { useDispatch } from 'react-redux';
// Slices
import { setResetResponse, setPrompt } from '../../app/features/iaSlice';
// Icons
import { RiDeleteBin6Line } from 'react-icons/ri';
// Styles
import './Delete.css';

const Delete = () => {
  const dispatch = useDispatch();

  const [showMessage, setShowMessage] = useState(false);

  const handleClic = () => {
    setShowMessage(!showMessage);
    dispatch(setResetResponse([]));
    dispatch(setPrompt(''));
    setShowMessage(false);
  };
  const getExamples = () => {
    return (
      <div className='messageDelete'>
        <button onClick={handleClic}>Delete all</button>
      </div>
    );
  };

  return (
    <button
      className='message-containerDelete'
      onClick={() => setShowMessage(!showMessage)}
    >
      <RiDeleteBin6Line />
      {showMessage && getExamples()}
    </button>
  );
};

export default Delete;
