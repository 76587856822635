import { toast } from 'react-toastify';
import { addNewFullFavorites } from '../../app/features/favoriteSlice';
import { deleteFavoriteById, getFavoriteLinks } from '../../services/favorites';
import { DeleteFavoriteNotification } from '../Notification';

export const handleGetFavorites = async (email, dispatch) => {
	try {
		const favorites = await getFavoriteLinks(email);
		dispatch(addNewFullFavorites(favorites));
		localStorage.setItem('favorites', JSON.stringify(favorites));
		return favorites;
	} catch (error) {
		console.error(error);
	}
};

export const handleRemoveFavorite = async (favoriteId) => {
	try {
		const deletedFavorite = await deleteFavoriteById(favoriteId);

		return deletedFavorite;
	} catch (error) {
		console.error(error);
	}
};

export const addFavoriteByUrl = (newFavorite) => {
	// Obtener los favoritos actuales de localStorage
	const favorites = JSON.parse(localStorage.getItem('favorites')) || [];

	// Verificar si la URL ya existe para evitar duplicados
	const exists = favorites.some((fav) => fav.url === newFavorite.url);
	if (exists) {
		console.error('La URL ya existe en favoritos:', newFavorite.url);
		return;
	}

	// Agregar el nuevo favorito al array
	const updatedFavorites = [...favorites, newFavorite];

	// Guardar en localStorage
	localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
};

export const getFavoriteByUrl = (searchUrl, favoriteList) => {
	const favorites = favoriteList || [];

	return favorites.find((favorite) => favorite.url === searchUrl) || null;
};

export const handleDeleteFavorite = async (
	url,
	favorites,
	favoriteType,
	user_email,
	dispatch,
	onDeleteFavorite
) => {
	try {
		const favoriteItem = getFavoriteByUrl(url, favorites);

		if (favoriteType === 'favorite') {
			const deletedFavorite = await handleRemoveFavorite(favoriteItem.id);
			if (deletedFavorite.sucess === 'Deleted') {
				toast(<DeleteFavoriteNotification />, {
					type: 'success',
				});
				handleGetFavorites(user_email, dispatch);
			}
		}

		if (favoriteType === 'detail' && onDeleteFavorite) {
			onDeleteFavorite();
		}
	} catch (error) {
		console.error('Error deleting favorite:', error);
	}
};
