import { toast } from 'react-toastify';
import {
	LinkCopiedNotification,
	NewFavoriteNotification,
} from '../Notification';
import { preventBehavior } from '../../utils/preventBehavior';
import { useEffect, useRef, useState } from 'react';
import { createFavorite } from '../../services/favorites';
import { useDispatch, useSelector } from 'react-redux';
import { handleGetFavorites } from './utils';

export const useFavoriteButton = () => {
	const dispatch = useDispatch();
	const [showTooltip, setShowTooltip] = useState(false);
	const buttonRef = useRef(null);
	const tooltipRef = useRef(null);
	const { user_email, user_name, user_lastname } = useSelector(
		(state) => state.user
	);

	const copyToClipboard = async (url) => {
		try {
			await navigator.clipboard.writeText(url);
			toast(<LinkCopiedNotification />, {
				type: 'success',
			});
		} catch (error) {
			console.error('Failed to copy URL:', error);
		}
	};

	const handleCreateNewFavorite = async (type, resource, event) => {
		preventBehavior(event);
		try {
			const userData = {
				email: user_email,
				full_name:
					user_email === user_name
						? user_email
						: `${user_name} ${user_lastname}`,
				institution_code: process.env.REACT_APP_INSTITUTION_CODE,
			};

			const newFavorite = await createFavorite(userData, type, resource);

			if (newFavorite.success === 'Favorite added') {
				toast(<NewFavoriteNotification />, {
					type: 'success',
				});
				handleGetFavorites(user_email, dispatch);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleToggleTooltip = (event) => {
		preventBehavior(event);
		setShowTooltip((prev) => !prev);
	};

	// Function to handle clicks outside the button
	const handleClickOutside = (event) => {
		if (
			buttonRef.current &&
			!buttonRef.current.contains(event.target) &&
			tooltipRef.current &&
			!tooltipRef.current.contains(event.target)
		) {
			setShowTooltip(false);
		}
	};

	useEffect(() => {
		if (showTooltip) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		// Cleanup event listener on unmount
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [showTooltip]);

	return {
		handleCreateNewFavorite,
		handleToggleTooltip,
		showTooltip,
		buttonRef,
		tooltipRef,
		copyToClipboard,
	};
};
