import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Services
import { sendUserSearchInformation } from '../../services/sendUserSearchInformation';
// Styles
import './Card.css';
import FavoriteButton from '../FavoriteButton';
import {
	getFavoriteByUrl,
	handleDeleteFavorite,
} from '../FavoriteButton/utils';

const Card = ({
	title,
	authors,
	abstract,
	publication_date,
	url,
	type,
	resource_origin,
	peer_reviewed,
	favoriteType = 'favorite', // favorite | detail
	favoriteCategory,
	onDeleteFavorite,
	showFavoriteButton = false,
}) => {
	const dispatch = useDispatch();
	const [favoriteStatus, setFavoriteStatus] = useState(false);
	const favorites = useSelector((state) => state.favorites.favorites);
	const abstractRef = useRef(null);
	// Slices
	const { user_email } = useSelector((state) => state.user);
	const { institutionCode } = useSelector((state) => state.institution);

	// Variables
	const [showFullAbstract, setShowFullAbstract] = useState(false);
	const totalCharacters = 250;

	const concatenatingAuthors = () => {
		return (
			<p
				className={`authorsCard ${
					favoriteType === 'detail' ? 'no-wrap-one-line-cut-text' : ''
				}`}
			>
				{Array.isArray(authors) ? authors.join(', ') : ''}
			</p>
		);
	};

	const handleClicCard = () => {
		sendUserSearchInformation(
			title,
			resource_origin,
			url,
			window.location.pathname,
			user_email,
			institutionCode
		);
		window.open(url, '_blank');
	};

	const getAbstract = () => {
		let abstractToShow = showFullAbstract
			? abstract
			: abstract?.substring(0, totalCharacters) + '...';

		if (showFullAbstract) {
			abstractToShow = abstract;
		} else {
			abstractToShow =
				abstract?.length < totalCharacters
					? abstract
					: abstract?.substring(0, totalCharacters) + '...';
		}
		return abstractToShow;
	};

	useEffect(() => {
		const hasFavorite = getFavoriteByUrl(url, favorites);

		setFavoriteStatus(!!hasFavorite);
	}, [favorites, url]);

	return (
		<div className='containerCard'>
			{title && (
				<p
					className={`titleCard ${
						favoriteType === 'detail' ? 'no-wrap-one-line-cut-text' : ''
					}`}
					onClick={handleClicCard}
					role='button'
					tabIndex='0'
				>
					{title}
				</p>
			)}
			{authors?.length > 0 && concatenatingAuthors()}
			<p
				className={`${
					showFullAbstract ? 'textCard  textCardFull' : 'textCard '
				} ${favoriteType === 'detail' ? 'no-wrap-three-line-cut-text' : ''}`}
				ref={abstractRef}
			>
				{getAbstract()}
			</p>

			{favoriteType === 'favorite' && abstract?.length > totalCharacters && (
				<button
					className='buttonAbstract'
					onClick={() => setShowFullAbstract(!showFullAbstract)}
				>
					Show {showFullAbstract ? 'less' : 'more'}
				</button>
			)}

			<div className='infoCard'>
				{type && <p className='category categoryType'>{type}</p>}

				{publication_date && typeof publication_date === 'string' && (
					<p className='category'>
						Published: {publication_date.split('T')[0]}
					</p>
				)}
				{peer_reviewed === true && (
					<p className='category peerReviewed'>Peer reviewed</p>
				)}
			</div>

			{showFavoriteButton && (
				<FavoriteButton
					type={favoriteType}
					category={favoriteCategory}
					favoriteData={{
						abstract,
						authors,
						publication_date,
						resource_origin,
						title,
						type,
						url,
					}}
					isFavorite={favoriteStatus}
					onDeleteFavorite={() =>
						handleDeleteFavorite(
							url,
							favorites,
							favoriteType,
							user_email,
							dispatch,
							onDeleteFavorite
						)
					}
					url={url}
				/>
			)}
		</div>
	);
};

export default Card;
