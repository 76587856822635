import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FavoriteCategory } from './types';
import { toast } from 'react-toastify';
import {
	deleteFavoriteById,
	getFavoritesByCategoryAndType,
} from '../../services/favorites';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteFavoriteItemNotification } from '../../Components/Notification';
import { handleGetFavorites } from '../../Components/FavoriteButton/utils';
import { useAuth } from '../../hooks/useAuth';

export const useFavoriteDetailPage = () => {
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const type = params.get('type');
	const { isAuth } = useAuth();
	const dispatch = useDispatch();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedFavorite, setSelectedFavorite] = useState(null);
	const [loading, setLoading] = useState(true);
	const { user_email } = useSelector((state) => state.user);
	const navigate = useNavigate();
	const [favorites, setFavorites] = useState([]);

	// Pagination States
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize] = useState(type === FavoriteCategory.MEDIA ? 8 : 6);
	const [totalItems, setTotalItems] = useState(0);

	const handleCloseDeleteModal = () => {
		setSelectedFavorite(null);
		setShowDeleteModal(false);
	};

	const handleShowDeleteModal = (favoriteId) => {
		setSelectedFavorite(favoriteId);
		setShowDeleteModal(true);
	};
	const handleGetFavoritesByCategory = async (email, type, page) => {
		setLoading(true);
		try {
			const response = await getFavoritesByCategoryAndType(
				email,
				type,
				pageSize,
				page
			);
			setFavorites(response?.data);
			setTotalItems(response.total);
			setLoading(false);

			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error('Error getting favorites by category:', error);
			toast.error('Error, please try again');
		}
	};

	const handleDeleteFavorite = async (favoriteId) => {
		try {
			await deleteFavoriteById(favoriteId);
			await handleGetFavorites(user_email, dispatch);
			handleGetFavoritesByCategory(user_email, type, currentPage);
			handleCloseDeleteModal();
			toast(<DeleteFavoriteItemNotification />, { type: 'success' });
		} catch (error) {
			console.error(error);
			toast.error('Error, please try again');
		}
	};

	const getTitle = (category) => {
		switch (category) {
			case FavoriteCategory.ARTICLES:
				return 'Articles';
			case FavoriteCategory.PATENTS:
				return 'Patents';
			case FavoriteCategory.NEWS:
				return 'News';
			case FavoriteCategory.MEDIA:
				return 'Media';
			case FavoriteCategory.BOOKS:
				return 'Books';
			case FavoriteCategory.INTERESTING_LINKS:
				return 'Interesting Links';
			case FavoriteCategory.SOFT_SKILLS:
				return 'Soft skills and American life';
			case FavoriteCategory.TUTORIALS:
				return 'Tutorials';
			default:
				return 'Favorites';
		}
	};

	useEffect(() => {
		if (!isAuth()) {
			navigate('/');
			return;
		}

		if (!type) {
			navigate('/my-favorites');
			return;
		}

		if (user_email) {
			handleGetFavoritesByCategory(user_email, type, 1);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user_email]);

	useEffect(() => {
		// If the user changes page, fetch new data:
		if (user_email && type) {
			handleGetFavoritesByCategory(user_email, type, currentPage);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	useEffect(() => {
		if (favorites.length === 0 && !loading) {
			navigate('/my-favorites');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [favorites, loading]);

	// Number of pages to render
	const totalPages = Math.ceil(totalItems / pageSize);

	return {
		type,
		getTitle,
		favorites,
		handleDeleteFavorite,
		showDeleteModal,
		handleCloseDeleteModal,
		selectedFavorite,
		handleShowDeleteModal,
		//
		totalPages,
		currentPage,
		setCurrentPage,
	};
};
