import './favoriteCard.css';
import TrashcanIcon from '../../Images/Login/Modal/trashIcon.svg';
import { preventBehavior } from '../../utils/preventBehavior';

const FavoriteCard = ({ image, title, articlesNumber, onDelete }) => {
	return (
		<div className='favorite-card add-zoom'>
			<div className='favorite-card__image'>
				<img alt='favorite imagen' src={image} />
			</div>
			<div className='favorite-card__content'>
				<div className='favorite-card__title'>{title}</div>
				<div className='favorite-card__articles'>
					{articlesNumber} {articlesNumber <= 1 ? 'Favorite' : 'Favorites'}
				</div>
			</div>

			<div className='favorite-card__settings'>
				<button
					onClick={(event) => {
						preventBehavior(event);

						if (onDelete) {
							onDelete();
						}
					}}
				>
					<img src={TrashcanIcon} alt='settings' className='add-zoom-x3' />
				</button>
			</div>
		</div>
	);
};

export default FavoriteCard;
